import {
  drinkIcon,
  eatIcon,
  idleIcon,
  walkIcon,
  layingIcon,
  trotIcon,
  urinateIcon
} from './icons';

const SKELETONS = [
  [0, 1],
  [0, 2],
  [1, 2],

  [1, 3],
  [2, 4],

  [5, 7],
  [6, 8],

  [7, 9],
  [8, 10],

  [11, 13],
  [12, 14],

  [13, 15],
  [14, 16],
];

const POINT_RADIUS = 4;

class Renderer {
  constructor(streamer) {
    this.streamer = streamer;
  }

  clear(canvas) {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  render(canvas, payload, timestamp, prevTimestamp, frameCoordToCanvasFn) {
    const tenSeconds = 1000 * 10;
    if (!payload || Date.now() - timestamp > tenSeconds) {
      return;
    }


    const ctx = canvas.getContext('2d');

    // draw lines

    const {clientWidth, clientHeight} = this.streamer.videoSrc;

    // find padding so that canvas fits images 'object-fit: contain;'

    // resize canvas
    canvas.width = clientWidth;
    canvas.height = clientHeight;

    if (this.streamer.shouldDrawPoses) {
      this.drawPoses(ctx, payload, frameCoordToCanvasFn);
    }

    if (this.streamer.shouldDrawBoxes) {
      this.drawBoxes(ctx, payload, frameCoordToCanvasFn);
    }

    this.drawBoxHoverHighlights(ctx, payload, frameCoordToCanvasFn);
    this.drawBoxActiveHighlights(ctx, payload, frameCoordToCanvasFn);

    this.drawTrackingIds(ctx, payload, frameCoordToCanvasFn);

    if (this.streamer.shouldDrawTimestamp) {
      this.drawFrameDeltaInSecs(
        ctx,
        clientWidth,
        clientHeight,
        timestamp,
        prevTimestamp
      );
    }
  }

  stop(canvas) {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  drawBoxes(ctx, payload, frameCoordToCanvasFn) {
    ctx.beginPath();

    for (const pose of payload.instances) {
      const {bbox} = pose;


      if (bbox) {
        const {x: startX, y: startY} = frameCoordToCanvasFn(
          bbox[0],
          bbox[1]
        );

        const {x: endX, y: endY} = frameCoordToCanvasFn(
          bbox[2],
          bbox[3]
        );

        ctx.rect(
          startX,
          startY,
          endX - startX,
          endY - startY
        );
      }
    }

    ctx.strokeStyle = '#64fe00';
    ctx.lineWidth = 1;
    ctx.stroke();
  }

  drawBoxHoverHighlights(ctx, payload, frameCoordToCanvasFn) {
    ctx.beginPath();

    for (const pose of payload.instances) {
      const {bbox, gId} = pose;

      if (!bbox || !gId) {
        continue;
      }

      const isHover = this.streamer.hoverId === gId;
      if (!isHover) {
        continue;
      }

      const {x: startX, y: startY} = frameCoordToCanvasFn(
        bbox[0],
        bbox[1]
      );

      const {x: endX, y: endY} = frameCoordToCanvasFn(
        bbox[2],
        bbox[3]
      );

      ctx.rect(
        startX,
        startY,
        endX - startX,
        endY - startY
      );
    }

    ctx.fillStyle = '#0003';
    ctx.lineWidth = 1;
    ctx.fill();
  }

  drawBoxActiveHighlights(ctx, payload, frameCoordToCanvasFn) {
    ctx.beginPath();

    for (const pose of payload.instances) {
      const {bbox, gId} = pose;

      if (!bbox || !gId) {
        continue;
      }

      const isActive = this.streamer.activeId === gId?.toString();
      if (!isActive) {
        continue;
      }

      const {x: startX, y: startY} = frameCoordToCanvasFn(
        bbox[0],
        bbox[1]
      );

      const {x: endX, y: endY} = frameCoordToCanvasFn(
        bbox[2],
        bbox[3]
      );

      ctx.roundRect(
        startX,
        startY,
        endX - startX,
        endY - startY,
        3
      );
    }

    ctx.strokeStyle = '#3abdee';
    ctx.lineWidth = 4;
    ctx.stroke();
  }

  drawPoses(ctx, payload, frameCoordToCanvasFn) {
    ctx.beginPath();

    const { confidence } = this.streamer;

    for (const pose of payload.instances) {
      const {bones} = pose;

      if (!bones) {
        continue;
      }

      SKELETONS.forEach((skeleton) => {
        const startCor = bones[skeleton[0]];
        const endCor = bones[skeleton[1]];

        const {x: startAdjX, y: startAdjY} = frameCoordToCanvasFn(
          startCor[0],
          startCor[1]
        );

        const {x: endAdjX, y: endAdjY} = frameCoordToCanvasFn(
          endCor[0],
          endCor[1]
        );

        if (startCor[2] >= confidence && endCor[2] >= confidence) {
          ctx.moveTo(startAdjX, startAdjY);
          ctx.lineTo(endAdjX, endAdjY);
          return;
        }

        if (startCor[2] >= confidence) {
          ctx.moveTo(startAdjX + POINT_RADIUS, startAdjY);
          ctx.arc(startAdjX, startAdjY, POINT_RADIUS, 0, Math.PI * 2);
          return;
        }

        if (endCor[2] >= confidence) {
          ctx.moveTo(endAdjX + POINT_RADIUS, endAdjY);
          ctx.arc(endAdjX, endAdjY, POINT_RADIUS, 0, Math.PI * 2);
          return;
        }
      });
    }

    ctx.strokeStyle = '#64fe00';
    ctx.lineWidth = 1;
    ctx.stroke();
  }

  drawTrackingIds(ctx, payload, frameCoordToCanvasFn) {
    const boxPaddingH = 4;
    const boxPaddingV = 2;
    const fontHeight = 16;

    ctx.font = "14px sans-serif";

    const {
      canDrawIcons,
      shouldDrawGIds,
      shouldDrawMovement,
      shouldDrawHeadPos,
      shouldUseCurrentWeight
    } = this.streamer || {};

    for (const pose of payload.instances) {
      const {
        bbox,
        gId = '',
        trackId = '',
        behavior,
        avgWeight
      } = pose;

      if (!gId) {
        continue;
      }

      let weight = shouldUseCurrentWeight
        ? (pose.weight || '').toString()
        : (avgWeight || '').toString();

      const [
        eat,
        drink,
        laying,
        idle,
        urinate,
        trot,
        run,
        walk,
      ] = (behavior || []);

      let [start_x, start_y] = bbox;
      const {x: adjX, y: adjY} = frameCoordToCanvasFn(
        start_x,
        start_y
      );

      const idLabel = this.streamer.shouldDrawPoses ? `t:${trackId},g:${gId}` : gId;
      const str = weight ? `${idLabel}, ${weight} lbs` : `${idLabel}`;

      const strLength = shouldDrawGIds ? ctx.measureText(str).width : 0;

      const behaviorToIcon = [
        { behavior: eat, icon: eatIcon },
        { behavior: drink, icon: drinkIcon },
        { behavior: laying, icon: layingIcon },
        { behavior: idle, icon: idleIcon },
        { behavior: urinate, icon: urinateIcon },
        { behavior: trot, icon: trotIcon },
        { behavior: walk, icon: walkIcon }
      ];

      const icons = [];
      if (canDrawIcons && shouldDrawMovement) {
        behaviorToIcon.forEach(({ behavior, icon }) => {
          if (behavior) {
            icons.push(icon);
          }
        });
      }

      if (!strLength && !icons.length) {
        break;
      }

      const textWidth = strLength ? strLength + (2 * boxPaddingH) : 0;
      const textPadding = strLength ? (2 * boxPaddingH) : 0;

      const boxHeight = fontHeight + (2 * boxPaddingV);
      const boxWidth = strLength + textPadding + (icons.length * (boxHeight + boxPaddingH));

      ctx.beginPath();

      ctx.rect(
        adjX,
        adjY,
        boxWidth,
        boxHeight
      );

      ctx.fillStyle = '#0007';

      ctx.fill();

      if (strLength) {
        const color = 'white';
        ctx.fillStyle = color;
        ctx.textAlign = 'left';
        ctx.fillText(str, adjX + boxPaddingH, adjY + boxPaddingV + 14);
      }

      icons.forEach((icon, i) => {
        const dx = adjX + textWidth + boxPaddingH + (i * (boxHeight + boxPaddingH));
        const dy = adjY;

        ctx.drawImage(icon, dx, dy, boxHeight, boxHeight);
      });
    }
  }

  drawFrameDeltaInSecs(ctx, clientWidth, clientHeight, timestamp, prevTimestamp) {
    if (!timestamp || !prevTimestamp) {
      return;
    }

    const boxPaddingH = 4;
    const boxPaddingV = 2;

    const fontHeight = 16;

    ctx.beginPath();

    ctx.font = "12px sans-serif";

    const delta = timestamp - prevTimestamp;

    const deltaStr = `${(delta / 1000).toFixed(2)}s`;
    const strLength = ctx.measureText(deltaStr).width;

    const boxWidth = strLength + (2 * boxPaddingH);

    ctx.rect(
      clientWidth - boxWidth,
      0,
      boxWidth,
      fontHeight + (2 * boxPaddingV)
    );

    ctx.fillStyle = '#000a';
    ctx.fill();

    ctx.fillStyle = 'white';
    ctx.textAlign = 'right';
    ctx.fillText(deltaStr, clientWidth - boxPaddingH, fontHeight);
  }
}

export default Renderer;
