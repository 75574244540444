import React from 'react'

const IconIdentification = props => {

    const {
        title = '',
        color = '#ffffff',
    } = props

    return (
        <>
            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dii_7731_907)">
                    <path d="M2 11C2 5.47715 6.47715 1 12 1H40C45.5229 1 50 5.47715 50 11V39C50 44.5229 45.5228 49 40 49H12C6.47715 49 2 44.5228 2 39V11Z" fill="white" />
                    <path d="M2.5 11C2.5 5.75329 6.75329 1.5 12 1.5H40C45.2467 1.5 49.5 5.75329 49.5 11V39C49.5 44.2467 45.2467 48.5 40 48.5H12C6.75329 48.5 2.5 44.2467 2.5 39V11Z" stroke="#E4E7EC" />
                    <path d="M22 21H22.01M16 18.2L16 22.6745C16 23.1637 16 23.4083 16.0553 23.6385C16.1043 23.8425 16.1851 24.0376 16.2947 24.2166C16.4184 24.4184 16.5914 24.5914 16.9373 24.9373L24.6059 32.6059C25.7939 33.7939 26.388 34.388 27.0729 34.6105C27.6755 34.8063 28.3245 34.8063 28.927 34.6105C29.612 34.388 30.2061 33.7939 31.3941 32.6059L33.6059 30.3941C34.7939 29.2061 35.388 28.612 35.6105 27.927C35.8063 27.3245 35.8063 26.6755 35.6105 26.0729C35.388 25.388 34.7939 24.7939 33.6059 23.6059L25.9373 15.9373C25.5914 15.5914 25.4184 15.4184 25.2166 15.2947C25.0376 15.1851 24.8425 15.1043 24.6385 15.0553C24.4083 15 24.1637 15 23.6745 15L19.2 15C18.0799 15 17.5198 15 17.092 15.218C16.7157 15.4097 16.4097 15.7157 16.218 16.092C16 16.5198 16 17.0799 16 18.2ZM22.5 21C22.5 21.2761 22.2761 21.5 22 21.5C21.7239 21.5 21.5 21.2761 21.5 21C21.5 20.7239 21.7239 20.5 22 20.5C22.2761 20.5 22.5 20.7239 22.5 21Z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <filter id="filter0_dii_7731_907" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7731_907" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7731_907" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="-2" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_7731_907" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_innerShadow_7731_907" />
                        <feOffset />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0" />
                        <feBlend mode="normal" in2="effect2_innerShadow_7731_907" result="effect3_innerShadow_7731_907" />
                    </filter>
                </defs>
            </svg>

            {title && <h4><span>{title}</span></h4>}
        </>
    );
}

export default IconIdentification;