import React from 'react'

const IconLimping = props => {

    const {
        title = '',
        color = '#ffffff',
    } = props

    return (
        <>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill={color} />
<path d="M25 14L16.0935 24.6879C15.7447 25.1064 15.5703 25.3157 15.5676 25.4925C15.5653 25.6461 15.6337 25.7923 15.7533 25.8889C15.8908 26 16.1632 26 16.708 26H24L23 34L31.9066 23.3121C32.2554 22.8936 32.4298 22.6843 32.4324 22.5075C32.4348 22.3539 32.3663 22.2077 32.2468 22.1111C32.1093 22 31.8368 22 31.292 22H24L25 14Z" stroke="#3E9CDB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

            {title && <h4><span>{title}</span></h4>}
        </>
    );
}

export default IconLimping;