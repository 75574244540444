import {createContext} from 'react';

const CameraStackContext = createContext({
  stack: [],
  addToStack: () => {},
  removeFromStack: () => {},
  closeTab: () => {}
});

export default CameraStackContext;
