import React, { useState } from 'react';

import css from './styles.module.css';

function CattleGraphInfo({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={css.container}>
      <div className={css.legendItem}>
        <span className={css.legendLabel}>Avg</span>
        <span className={css.legendLine} style={{ borderColor: '#383838' }} />
      </div>
      <div className={css.legendItem}>
        <span className={css.legendLabel}>±10%</span>
        <span
          className={css.legendLine}
          style={{
            borderColor: '#383838aa',
            borderStyle: 'dashed'
          }}
        />
      </div>
    </div>
  );
}

export default CattleGraphInfo;
