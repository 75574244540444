import React from 'react';

import useQuery from '../../hooks/useQuery';

import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import HeaderStat from './HeaderStat';

import Client from '../../apis/Client';

import css from './styles.module.scss';

function Header(props) {
  const { locationId } = props;

  const url = `/api/v1/producers/locations/${locationId}/cattle/stats`;

  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['get_dashboard_stats', locationId],
    queryFn: () => Client.get(url),
  });

  const mapStats = (
    <>
      <HeaderStat label="Watch List" color="#767B80" data={data?.watch.total} />
      <HeaderStat label="Pull List" color="#EBBF4F" data={data?.pull.total} />
      {/*<HeaderStat label="Vet Check" color="#F16060" data={data?.vet.total} />*/}
    </>
  );

  const healthyPerc = Math.floor((data?.all.healthy / data?.all.total) * 100);

  return isError
    ? <ErrorBox show={isError} message={error.message} />
    : (
      <Loader loading={isLoading}>
        <div className={`${css.stats} ${css.map}`}>
          {mapStats}
          <HeaderStat
            label="Healthy"
            color="#68CB3A"
            data={data?.all.total}
            subdata={data?.all.total ? `(${healthyPerc}%)` : '(100%)'}
          />
        </div>
      </Loader>
    );
}

export default Header;
