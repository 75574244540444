import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import css from './styles.module.scss';
import HomeButton from '../Button/HomeButton';
import useIntersectionObserver from '../../../hooks/IntersectionObserver';

const HomeHero = ({ h1 = '', sub = '', img = '', imgm = '' }) => {
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundImage: `url(${img})`,
    backgroundSize: 'contain',
    backgroundPosition: 'right top',
  });
  const [backgroundStyleM, setBackgroundStyleM] = useState({
    backgroundImage: `url(${imgm})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [h1Ref, h1InView] = useIntersectionObserver({ threshold: 0.1 });
  const [pRef, pInView] = useIntersectionObserver({ threshold: 0.1 });
  const [buttonRef, buttonInView] = useIntersectionObserver({ threshold: 0.1 });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setBackgroundStyle({
          backgroundImage: 'none',
          padding: '0 0 0 0',
          backgroundImage: `url(${img})`,
          backgroundSize: 'contain',
          backgroundPosition: 'right top',
        });
      } else {
        setBackgroundStyle({
          backgroundImage: `url(${img})`,
          backgroundSize: 'contain',
          backgroundPosition: 'right top',
        });
      }
    };

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Set the component as loaded
    setIsLoaded(true);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [img]);

  const getAnimationDelay = (index) => {
    return `${index * 0.5}s`;
  };

  return (
    <>
    <div id={css.hero} className={`d-flex flex-row justify-content-center position-relative`} style={backgroundStyle}>
      <div className={css.innerWrapper}>
        <div className={`col-lg-6 d-flex flex-column justify-content-center ${css.content}`}>
          <h1 ref={h1Ref} className={`mb-5 pb-5 position-relative ${css.animatedText} ${(h1InView || isLoaded) ? css.inView : ''}`} style={{ animationDelay: getAnimationDelay(0) }}>{h1}</h1>
          <p ref={pRef} className={`mb-0 ${css.animatedText} ${(pInView || isLoaded) ? css.inView : ''}`} style={{ animationDelay: getAnimationDelay(1) }}>{sub}</p>
          <div className={css.buttonContainer}>
            <Link to="/how-it-works" onClick={() => window.scrollTo(0, 0)}>
              <HomeButton ghost className={`${css.animatedText} ${(buttonInView || isLoaded) ? css.inView : ''}`} style={{ animationDelay: getAnimationDelay(2) }}>How it works</HomeButton>
            </Link>
            <Link to="/request-demo" onClick={() => window.scrollTo(0, 0)}>
              <HomeButton className={`${css.animatedText} ${(buttonInView || isLoaded) ? css.inView : ''}`} style={{ animationDelay: getAnimationDelay(2) }}>Request a demo</HomeButton>
            </Link>
          </div>
        </div>
        <div className={`col-lg-6`}>
          <div className={css.overlay}></div>
        </div>
      </div>
      
    </div>
    <div className={css.image} style={backgroundStyleM}>abc</div>
    </>
  );
};


export default HomeHero;