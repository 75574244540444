import React, { useRef } from 'react'
import { Link } from 'react-router-dom';

// images
import imgAnalytics from '../images/analytics.jpg';
import imgEcosystems from '../images/ecosystems.jpg';
import imgGrow from '../images/grow.jpg';
import imgHero from '../images/hero-bg.png'
import imgHeroM from '../images/hero.jpg'
import imgInTheKnow from '../images/in-the-know.jpg';
import imgAiDriven from '../images/ai-driven-cattle.jpg';
import imgScience from '../images/science-cattle.jpg';
import imgInstall from '../images/how-integrate.jpg'
import imgMonitoring from '../images/how-real-time.jpg'
import imgInstantAlerts from '../images/how-instant-alerts.jpg'
import imgData from '../images/how-data-driven.jpg'
import imgInstallM from '../images/how-integrate-m.png'
import imgMonitoringM from '../images/how-real-time-m.png'
import imgInstantAlertsM from '../images/how-instant-alerts-m.png'
import imgDataM from '../images/how-data-driven-m.png'
import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import css from './styles.module.scss';
import Topbar from './Topbar';

import HomeHero from './Components/RequestHero/RequestHero';
import HomeCard from './Components/HomeCard/HomeCard';
import HomeButton from './Components/Button/HomeButton';
import LogoBlock from '../components/LogoBlock/LogoBlock';
import IconAI from '../icons/AI';
import IconCamera from '../icons/Camera';
import IconSecurity from '../icons/Security';
import IconPrivacy from '../icons/Privacy';
import IconBilling from '../icons/Billing';
import IconHardware from '../icons/Hardware';
import IconConnectivity from '../icons/Connectivity';
import IconHealth from '../icons/Health';
import IconAnalytics from '../icons/Analytics';
import IconCompliance from '../icons/Compliance';
import IconCart from '../icons/Cart';
import IconProfit from '../icons/Profit';
import IconAlerts from '../icons/Alerts';
import IconTracking from '../icons/Tracking';
import IconIdentification from '../icons/Identification';
import IconHeadHanging from '../icons/HeadHanging';
import IconTag from '../icons/Tag';
import IconLimping from '../icons/Limping';
import IconInstall from '../icons/Install';
import IconMonitoring from '../icons/Monitoring';
import IconInstantAlerts from '../icons/InstantAlerts';
import IconData from '../icons/Data';
import IconCommication from '../icons/Communication';
import IconNoTouch from '../icons/NoTouch';
import IconTaylored from '../icons/Taylored';
import IconProactive from '../icons/Proactive';
import IconRTMonitoring from '../icons/RTMonitoring';
import Home from './Home';

const HomeContent = ({ title = '', content = '', classes = 'col-12' }) => {
  return (
    <div className={`d-flex flex-column position-relative ${classes} ${css.homeContent} ${css.prod}`} style={{ zIndex: 10 }}>
      {title && <h2 className={`mb-0`}>{title}</h2>}
      {content}
    </div>
  );
};



const RequestDemo = () => {
  const formRef = useRef(null);
  
    
  return (
    <>
      <Topbar />
      <main id="top" className={`${css.main} ${css.bgWhite}`}>
      <section className={`container d-flex flex-column ${css.container}`}>
        
      <HomeHero
          h1={<>Requst a Demo.</>}
          sub={`Fill out the form below and our HerdSense™ team will be in touch to schedule a demo.`}
          img={imgHero}
          imgm={imgHeroM} />
        
        
        <HomeCard id='footer' >
          <a href="#top" className={css.logoHolder}>
            <div className={css.logoIcons}>
              <span>
                <LogoHorns fill='#000000' />
              </span>
              {/*<span>
                <LogoCattle fill='#000000' />
              </span>
              <span>
                <LogoPig fill='#000000' />
              </span>*/}
            </div>
            <h4>HerdSense</h4>
          </a>

          <HomeContent
            content={
              <>
                <p className={css.footerText}>HerdSense™<br></br>Redefining Herd Health Management</p>
              </>
            }
            classes='col-12'
          />

          <div className={css.linksContainer}>
            <Link to="#top" onClick={() => window.scrollTo(0, 0)}>Request a demo</Link>
            <Link to="/support">Support</Link>
            <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy</Link>
          </div>
          <div className={css.footerHr} />
          <div>
            <p className={css.copyRight}>© 2024 HerdSense. All rights reserved.</p>
          </div>
        </HomeCard>
      </section>
    </main>
    </>
  );
};

export default RequestDemo;