import React from 'react';

const IconInstall = (props) => {
    const {title = '', color = '#ffffff'} = props;

    return (
        <>
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
                    fill="#D8ECF8"
                />
                <path
                    d="M25 15C25 14.4477 24.5523 14 24 14C23.4477 14 23 14.4477 23 15V24.5858L19.7071 21.2929C19.3166 20.9024 18.6834 20.9024 18.2929 21.2929C17.9024 21.6834 17.9024 22.3166 18.2929 22.7071L23.2929 27.7071C23.6834 28.0976 24.3166 28.0976 24.7071 27.7071L29.7071 22.7071C30.0976 22.3166 30.0976 21.6834 29.7071 21.2929C29.3166 20.9024 28.6834 20.9024 28.2929 21.2929L25 24.5858V15Z"
                    fill="#3E9CDB"
                />
                <path
                    d="M15 26C15.5523 26 16 26.4477 16 27V28.2C16 29.0566 16.0008 29.6389 16.0376 30.089C16.0734 30.5274 16.1383 30.7516 16.218 30.908C16.4097 31.2843 16.7157 31.5903 17.092 31.782C17.2484 31.8617 17.4726 31.9266 17.911 31.9624C18.3611 31.9992 18.9434 32 19.8 32H28.2C29.0566 32 29.6389 31.9992 30.089 31.9624C30.5274 31.9266 30.7516 31.8617 30.908 31.782C31.2843 31.5903 31.5903 31.2843 31.782 30.908C31.8617 30.7516 31.9266 30.5274 31.9624 30.089C31.9992 29.6389 32 29.0566 32 28.2V27C32 26.4477 32.4477 26 33 26C33.5523 26 34 26.4477 34 27V28.2413C34 29.0463 34 29.7106 33.9558 30.2518C33.9099 30.8139 33.8113 31.3306 33.564 31.816C33.1805 32.5686 32.5686 33.1805 31.816 33.564C31.3306 33.8113 30.8139 33.9099 30.2518 33.9558C29.7106 34 29.0463 34 28.2413 34H19.7587C18.9537 34 18.2894 34 17.7482 33.9558C17.1861 33.9099 16.6694 33.8113 16.184 33.564C15.4314 33.1805 14.8195 32.5686 14.436 31.816C14.1887 31.3306 14.0901 30.8139 14.0442 30.2518C14 29.7106 14 29.0463 14 28.2413V27C14 26.4477 14.4477 26 15 26Z"
                    fill="#3E9CDB"
                />
            </svg>

            {title && (
                <h4>
                    <span>{title}</span>
                </h4>
            )}
        </>
    );
};

export default IconInstall;
