import React from 'react'

const IconData = props => {

    const {
        title = '',
        color = '#ffffff',
    } = props

    return (
        <>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill="#D8ECF8" />
                <path d="M33.2 34C33.48 34 33.62 34 33.727 33.9455C33.8211 33.8976 33.8976 33.8211 33.9455 33.727C34 33.62 34 33.48 34 33.2V22.8C34 22.52 34 22.38 33.9455 22.273C33.8976 22.1789 33.8211 22.1024 33.727 22.0545C33.62 22 33.48 22 33.2 22L30.8 22C30.52 22 30.38 22 30.273 22.0545C30.1789 22.1024 30.1024 22.1789 30.0545 22.273C30 22.38 30 22.52 30 22.8V25.2C30 25.48 30 25.62 29.9455 25.727C29.8976 25.8211 29.8211 25.8976 29.727 25.9455C29.62 26 29.48 26 29.2 26H26.8C26.52 26 26.38 26 26.273 26.0545C26.1789 26.1024 26.1024 26.1789 26.0545 26.273C26 26.38 26 26.52 26 26.8V29.2C26 29.48 26 29.62 25.9455 29.727C25.8976 29.8211 25.8211 29.8976 25.727 29.9455C25.62 30 25.48 30 25.2 30H22.8C22.52 30 22.38 30 22.273 30.0545C22.1789 30.1024 22.1024 30.1789 22.0545 30.273C22 30.38 22 30.52 22 30.8V33.2C22 33.48 22 33.62 22.0545 33.727C22.1024 33.8211 22.1789 33.8976 22.273 33.9455C22.38 34 22.52 34 22.8 34L33.2 34Z" stroke="#3E9CDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22 18.8C22 18.52 22 18.38 22.0545 18.273C22.1024 18.1789 22.1789 18.1024 22.273 18.0545C22.38 18 22.52 18 22.8 18H25.2C25.48 18 25.62 18 25.727 18.0545C25.8211 18.1024 25.8976 18.1789 25.9455 18.273C26 18.38 26 18.52 26 18.8V21.2C26 21.48 26 21.62 25.9455 21.727C25.8976 21.8211 25.8211 21.8976 25.727 21.9455C25.62 22 25.48 22 25.2 22H22.8C22.52 22 22.38 22 22.273 21.9455C22.1789 21.8976 22.1024 21.8211 22.0545 21.727C22 21.62 22 21.48 22 21.2V18.8Z" stroke="#3E9CDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15 24.8C15 24.52 15 24.38 15.0545 24.273C15.1024 24.1789 15.1789 24.1024 15.273 24.0545C15.38 24 15.52 24 15.8 24H18.2C18.48 24 18.62 24 18.727 24.0545C18.8211 24.1024 18.8976 24.1789 18.9455 24.273C19 24.38 19 24.52 19 24.8V27.2C19 27.48 19 27.62 18.9455 27.727C18.8976 27.8211 18.8211 27.8976 18.727 27.9455C18.62 28 18.48 28 18.2 28H15.8C15.52 28 15.38 28 15.273 27.9455C15.1789 27.8976 15.1024 27.8211 15.0545 27.727C15 27.62 15 27.48 15 27.2V24.8Z" stroke="#3E9CDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14 14.8C14 14.52 14 14.38 14.0545 14.273C14.1024 14.1789 14.1789 14.1024 14.273 14.0545C14.38 14 14.52 14 14.8 14H17.2C17.48 14 17.62 14 17.727 14.0545C17.8211 14.1024 17.8976 14.1789 17.9455 14.273C18 14.38 18 14.52 18 14.8V17.2C18 17.48 18 17.62 17.9455 17.727C17.8976 17.8211 17.8211 17.8976 17.727 17.9455C17.62 18 17.48 18 17.2 18H14.8C14.52 18 14.38 18 14.273 17.9455C14.1789 17.8976 14.1024 17.8211 14.0545 17.727C14 17.62 14 17.48 14 17.2V14.8Z" stroke="#3E9CDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            {title && <h4><span>{title}</span></h4>}
        </>
    );
}

export default IconData;