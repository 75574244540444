import React from 'react'

const IconTaylored = props => {

  const {
    title = '',
    color = '#3099FD',
  } = props

  return (
    <>
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dii_7751_123)">
<path d="M2 11C2 5.47715 6.47715 1 12 1H40C45.5229 1 50 5.47715 50 11V39C50 44.5229 45.5228 49 40 49H12C6.47715 49 2 44.5228 2 39V11Z" fill="#475467"/>
<path d="M3 11C3 6.02944 7.02944 2 12 2H40C44.9706 2 49 6.02944 49 11V39C49 43.9706 44.9706 48 40 48H12C7.02944 48 3 43.9706 3 39V11Z" stroke="url(#paint0_linear_7751_123)" stroke-width="2"/>
<path d="M25.2827 16.4533C25.5131 15.9864 25.6284 15.7529 25.7848 15.6783C25.9209 15.6134 26.0791 15.6134 26.2152 15.6783C26.3717 15.7529 26.4869 15.9864 26.7174 16.4533L28.9041 20.8833C28.9721 21.0211 29.0061 21.0901 29.0558 21.1436C29.0999 21.191 29.1527 21.2293 29.2113 21.2566C29.2776 21.2874 29.3536 21.2985 29.5057 21.3208L34.397 22.0357C34.9121 22.111 35.1696 22.1486 35.2888 22.2744C35.3925 22.3839 35.4412 22.5343 35.4215 22.6838C35.3988 22.8556 35.2124 23.0372 34.8395 23.4004L31.3014 26.8464C31.1912 26.9538 31.136 27.0076 31.1004 27.0715C31.0689 27.128 31.0487 27.1902 31.0409 27.2545C31.0321 27.3271 31.0451 27.403 31.0711 27.5547L31.906 32.4221C31.994 32.9355 32.038 33.1922 31.9553 33.3445C31.8833 33.477 31.7554 33.57 31.6071 33.5975C31.4366 33.6291 31.2061 33.5078 30.7451 33.2654L26.3724 30.9658C26.2361 30.8942 26.168 30.8584 26.0962 30.8443C26.0327 30.8318 25.9673 30.8318 25.9038 30.8443C25.832 30.8584 25.7639 30.8942 25.6277 30.9658L21.2549 33.2654C20.7939 33.5078 20.5634 33.6291 20.393 33.5975C20.2447 33.57 20.1167 33.477 20.0447 33.3445C19.962 33.1922 20.006 32.9355 20.0941 32.4221L20.9289 27.5547C20.9549 27.403 20.9679 27.3271 20.9591 27.2545C20.9513 27.1902 20.9311 27.128 20.8996 27.0715C20.864 27.0076 20.8089 26.9538 20.6986 26.8464L17.1606 23.4004C16.7877 23.0372 16.6012 22.8556 16.5785 22.6838C16.5588 22.5343 16.6076 22.3839 16.7113 22.2744C16.8304 22.1486 17.088 22.111 17.603 22.0357L22.4943 21.3208C22.6464 21.2985 22.7225 21.2874 22.7887 21.2566C22.8474 21.2293 22.9002 21.191 22.9442 21.1436C22.9939 21.0901 23.0279 21.0211 23.096 20.8833L25.2827 16.4533Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_dii_7751_123" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7751_123"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7751_123" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_7751_123"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_innerShadow_7751_123"/>
<feOffset/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_7751_123" result="effect3_innerShadow_7751_123"/>
</filter>
<linearGradient id="paint0_linear_7751_123" x1="26" y1="1" x2="26" y2="49" gradientUnits="userSpaceOnUse">
<stop stop-color="white" stop-opacity="0.12"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

      {title && <h4><span>{title}</span></h4>}
    </>
  );
}

export default IconTaylored;