import React from 'react';
import useMutation from '../../hooks/useMutation';

import Client from '../../apis/Client';

import FormModal from '../../components/FormModal';
import RoleSelect from '../../components/RoleSelect';
import CustomInput from '../../components/forms/CustomInput';

import useQuery from '../../hooks/useQuery';

const validate = (values) => {
    let errors = {};

    return errors;
};

const UserModal = ({
    showModal,
    setShowModal = () => {},
    title = 'User',
    user,
    companyId,
    refetch,
}) => {
    const { data: roles } = useQuery({
        queryKey: ['roles'],
        queryFn: () => {
            return Client.get('/api/v1/admin/roles');
        },
    });

    const {
        mutate: submitRequest,
        error,
    } = useMutation({
        mutationKey: ['create-edit-user'],
        mutationFn: (data) => {
            const body = { ...data, roles: [data.roles] };
            if (user) {
                return Client.put(`/api/v1/admin/customers/${companyId}/users/${user.id}`, body);
            } else {
                return Client.post(`/api/v1/admin/customers/${companyId}/users`, body);
            }
        },
        onSuccess: () => {
            setShowModal(false);
            refetch();
        },
        useErrorBoundary: (error) => error.status === 401,
    });

    const initialValues = {
        firstName: user?.user.first_name || '',
        lastName: user?.user.last_name || '',
        phone: user?.user.phone || '',
        email: user?.user.email || '',
        // roles: user?.roles.map(r => r.id) || []
        roles: user?.roles
    };

    const onSubmit = (values) => {
        submitRequest(values);
    };

    const headerLabel = user ? `Edit ${user.user.first_name} ${user.user.last_name}` : 'Add User';

    return (
        <FormModal
          setShowModal={setShowModal}
          showModal={showModal}
          title={headerLabel}
          submitBtnLabel={user ? "Update" : "Create"}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          error={error}
        >
            {(formik) => (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                }}>
                    <CustomInput
                        label="First Name"
                        name="firstName"
                        formik={formik}
                        required
                    />

                    <CustomInput
                        label="Last Name"
                        name="lastName"
                        formik={formik}
                        required
                    />

                    <RoleSelect
                        label="Roles"
                        name="roles"
                        formik={formik}
                        roles={roles}
                        single
                    />

                    <CustomInput
                        label="Email"
                        name="email"
                        formik={formik}
                        required
                        disabled={!!user}
                    />

                    <CustomInput
                        label="Phone"
                        name="phone"
                        formik={formik}
                        required
                    />
                </div>
            )}
        </FormModal>
    );
};

export default UserModal;
