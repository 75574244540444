import React, {useContext} from 'react';
import {Outlet} from 'react-router-dom';
import {Button} from 'react-bootstrap';

import AuthContext from '../context/AuthContext';

import useMutation from '../hooks/useMutation';

import APIClient from '../apis/Client';

import TermsOfUse from '../components/TermsOfUse';

import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import glCss from '../Login/styles.module.scss';
import css from './css.module.scss';

function EulaRequired({children}) {
    const {userData, setUserData, logout} = useContext(AuthContext);

    const {mutate: acceptEula} = useMutation({
        mutationFn: () => APIClient.post('/api/v1/auth/sign-eula'),
        onSuccess: (res) => {
          setUserData(res);
        },
    });

    if (userData?.eula?.length) {
        return <Outlet>{children}</Outlet>;
    }

    return (
      <div id={glCss.login}>
        <div id={glCss.view}>
          <div id={glCss.viewInner}>
            <div className={css.paneLogin}>
              <div className={`${glCss.paneInner} ${css.paneInner}`}>
                <div className={`${glCss.logo} ${css.logo}`}>
                  <div className={glCss.logoIcons}>
                    <span className={css.span}>
                      <LogoHorns fill="#29313B" />
                    </span>
                    {/*<span className={css.span}>
                      <LogoCattle fill="#29313B" />
                    </span>
                    <span className={css.span}>
                      <LogoPig fill="#29313B" />
                    </span>*/}
                  </div>
                </div>

                <TermsOfUse />

                <div className={css.actions}>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={acceptEula}
                  >Accept</Button>
                  <Button
                    type="button"
                    variant="danger"
                    onClick={logout}
                  >Decline</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default EulaRequired;
