import React from 'react';

const IconTracking = props => {
  const {
    title = '',
    color = '#ffffff',
  } = props;

  return (
    <>
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dii_7731_948)">
          <path d="M2 11C2 5.47715 6.47715 1 12 1H40C45.5229 1 50 5.47715 50 11V39C50 44.5229 45.5228 49 40 49H12C6.47715 49 2 44.5228 2 39V11Z" fill="white" />
          <path d="M2.5 11C2.5 5.75329 6.75329 1.5 12 1.5H40C45.2467 1.5 49.5 5.75329 49.5 11V39C49.5 44.2467 45.2467 48.5 40 48.5H12C6.75329 48.5 2.5 44.2467 2.5 39V11Z" stroke="#E4E7EC" />
          <path d="M29 17.6C29 17.0399 29 16.7599 28.891 16.546C28.7951 16.3578 28.6422 16.2049 28.454 16.109C28.2401 16 27.9601 16 27.4 16H24.6C24.0399 16 23.7599 16 23.546 16.109C23.3578 16.2049 23.2049 16.3578 23.109 16.546C23 16.7599 23 17.0399 23 17.6V20.4C23 20.9601 23 21.2401 22.891 21.454C22.7951 21.6422 22.6422 21.7951 22.454 21.891C22.2401 22 21.9601 22 21.4 22H18.6C18.0399 22 17.7599 22 17.546 22.109C17.3578 22.2049 17.2049 22.3578 17.109 22.546C17 22.7599 17 23.0399 17 23.6V26.4C17 26.9601 17 27.2401 17.109 27.454C17.2049 27.6422 17.3578 27.7951 17.546 27.891C17.7599 28 18.0399 28 18.6 28H21.4C21.9601 28 22.2401 28 22.454 28.109C22.6422 28.2049 22.7951 28.3578 22.891 28.546C23 28.7599 23 29.0399 23 29.6V32.4C23 32.9601 23 33.2401 23.109 33.454C23.2049 33.6422 23.3578 33.7951 23.546 33.891C23.7599 34 24.0399 34 24.6 34H27.4C27.9601 34 28.2401 34 28.454 33.891C28.6422 33.7951 28.7951 33.6422 28.891 33.454C29 33.2401 29 32.9601 29 32.4V29.6C29 29.0399 29 28.7599 29.109 28.546C29.2049 28.3578 29.3578 28.2049 29.546 28.109C29.7599 28 30.0399 28 30.6 28H33.4C33.9601 28 34.2401 28 34.454 27.891C34.6422 27.7951 34.7951 27.6422 34.891 27.454C35 27.2401 35 26.9601 35 26.4V23.6C35 23.0399 35 22.7599 34.891 22.546C34.7951 22.3578 34.6422 22.2049 34.454 22.109C34.2401 22 33.9601 22 33.4 22L30.6 22C30.0399 22 29.7599 22 29.546 21.891C29.3578 21.7951 29.2049 21.6422 29.109 21.454C29 21.2401 29 20.9601 29 20.4V17.6Z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <filter id="filter0_dii_7731_948" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7731_948" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7731_948" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_7731_948" />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default IconTracking;