import React from 'react';
import { useNavigate } from 'react-router-dom';

import TopHeader from '../../components/TopHeader';

import css from './styles.module.scss';

function Topbar(props) {
    const {
        navOpen = false,
        setNavOpen = () => {}
    } = props;

    const navigate = useNavigate();

    const links = [];

    const loginBtn = (
      <button
        className={`btn btn-primary px-5 position-relative`}
        onClick={() => navigate('/login')}
        style={{
          fontSize: 16,
        }}
      >
        <span className={`position-relative`}>Login</span>
      </button>
    );

    return (
        <TopHeader
            links={links}
            navOpen={navOpen}
            setNavOpen={setNavOpen}
            customEls={loginBtn}
            label="Herd Sense"
        />
    );
}

export default Topbar;
