import React, { useState, useEffect, useRef } from 'react';
import css from './styles.module.scss';
import useIntersectionObserver from '../../../hooks/IntersectionObserver';

const RequestHero = ({ h1 = '', sub = '', img = '', imgm = '' }) => {

  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundImage: `url(${img})`,
    backgroundSize: 'contain',
    backgroundPosition: 'right top',
  });
  const [backgroundStyleM, setBackgroundStyleM] = useState({
    backgroundImage: `url(${imgm})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [h1Ref, h1InView] = useIntersectionObserver({ threshold: 0.1 });
  const [pRef, pInView] = useIntersectionObserver({ threshold: 0.1 });
  const [buttonRef, buttonInView] = useIntersectionObserver({ threshold: 0.1 });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setBackgroundStyleM({
          backgroundImage: `url(${imgm})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        });
      } else {
        setBackgroundStyle({
          backgroundImage: `url(${img})`,
          backgroundSize: 'contain',
          backgroundPosition: 'right top',
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    setIsLoaded(true);

    return () => window.removeEventListener('resize', handleResize);
  }, [img, imgm]);

  const getAnimationDelay = (index) => {
    return `${index * 0.5}s`;
  };

  const formRef = useRef(null)
  const [message, setMessage] = useState('');
  const scriptUrl = 'https://script.google.com/macros/s/AKfycbx8AOqBZmTY4MwF-p0rEgcUNX2LPyn8xpH-q2MuGT-luLBrU1FkyDSeF9hDd_ng97n7aQ/exec'; // Define your Google Sheets script URL here

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const formData = new FormData(formRef.current);
    formData.append('sheetName', 'Request');
    const email = formData.get('email');
    const phone = formData.get('phone');

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      //alert('Please enter a valid email address.');
      setMessage('Please enter a valid email address.');
      return;
    }
    // Validate phone number format
    const phonePattern = /^(\d{10}|\(\d{3}\)\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\d{3}\d{4})$/;
    if (phone && !phonePattern.test(phone)) {
      setMessage('Please enter a valid phone number.');
      return;
    }

    fetch(scriptUrl, { method: 'POST', body: formData })
      .then((res) => {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return res.json();
        } else {
          return res.text();
        }
      })
      .then((data) => {
        setMessage(typeof data === 'string' ? data : JSON.stringify(data));
      })
      .catch((error) => {
        console.log(error);
        setMessage('An error occurred. Please try again.');
      });
  };

  return (
    <>
      <div id={css.hero} className={`d-flex flex-row justify-content-center position-relative`} style={backgroundStyle}>
        <div className={css.innerWrapper} style={{ padding: '10px 0 0 0' }}>
          <div className={`col-lg-6 d-flex flex-column justify-content-center ${css.content}`}>
            <a href='./' className={css.backButton}>&lt; &nbsp;  Back</a>
            <h1 ref={h1Ref} className={`mb-5 pb-5 position-relative ${css.animatedText} ${(h1InView || isLoaded) ? css.inView : ''}`} style={{ animationDelay: getAnimationDelay(0) }}>{h1}</h1>
            <p ref={pRef} className={`mb-0 ${css.animatedText} ${(pInView || isLoaded) ? css.inView : ''}`} style={{ animationDelay: getAnimationDelay(1) }}>{sub}</p>
            <div className={css.buttonContainer}>

              <form ref={formRef} onSubmit={handleSubmitForm} className="w-100">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <input type="text" name="fname" className="form-control" placeholder="First Name" required />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input type="text" name="lname" className="form-control" placeholder="Last Name" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <input type="text" name="cname" className="form-control" placeholder="Company Name" required />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input type="email" name="email" className="form-control" placeholder="Email" required />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input type="text" name="phone" className="form-control" placeholder="Phone Number" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary w-100">Submit</button>
                  </div>
                </div>
              </form>
              {message && <div className="mt-3 w-100">{message}</div>}
            </div>
          </div>
          <div className={`col-lg-6`}>
            <div className={css.overlay}></div>
          </div>
        </div>
      </div>
      <div className={css.image} style={backgroundStyleM}>abc</div>
    </>
  );
};

export default RequestHero;