import React from 'react';

const IconMonitoring = (props) => {
    const {title = '', color = '#ffffff'} = props;

    return (
        <>
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
                    fill="#D8ECF8"
                />
                <path
                    d="M26.5 31L28.5 33L33 28.5M33.9851 24.5499C33.995 24.3678 34 24.1845 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.4354 18.3365 33.858 23.7385 33.9966M24 18V24L27.7384 25.8692"
                    stroke="#3E9CDB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            {title && (
                <h4>
                    <span>{title}</span>
                </h4>
            )}
        </>
    );
};

export default IconMonitoring;
