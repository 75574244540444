import React, { useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router-dom';

import PenListView from './PenListView';

import Marker from './Marker';

import css from '../styles.module.scss';

const { REACT_APP_GOOGLE_MAP_KEY } = process.env;

const FIFTEEN_MINUTES = 1000 * 60 * 15;

const options = {
  mapTypeId: 'satellite',
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: true,
  fullscreenControl: false
};

function PoleMap(props) {
  const { locationId, data, isFetching } = props;

  const navigate = useNavigate();

  function selectDevice(deviceId) {
    const url = `/producers/herd-sense?locationId=${locationId}&deviceId=${deviceId}`;
    navigate(url);
  }

  function onMapsLoaded({ map, maps }) {
    const bounds = new maps.LatLngBounds();
    data.forEach((device) => {
      bounds.extend({
        lat: device.latitude,
        lng: device.longitude
      });
    });

    map.fitBounds(bounds);
  }

  const center = useMemo(() => {
    if (!data?.length) {
      return null;
    }

    const origin = data
      .reduce((coords, device) => {
        coords.lat += device.latitude;
        coords.lng += device.longitude;
        return coords;
      }, {lat: 0, lng: 0});

    origin.lat /= data.length;
    origin.lng /= data.length;

    return origin;
  }, [data]);

  if (isFetching) {
    return null;
  }

  const markers = data?.map((device) => {
    const online = new Date().getTime() - new Date(device.last_heartbeat).getTime() < FIFTEEN_MINUTES;
    return (
      <Marker
        key={device.id}
        online={online}
        lat={device.latitude}
        lng={device.longitude}
        label={device.name}
        onClick={() => selectDevice(device.id)}
      />
    )
  });

  return (
    <div className={css.mapView}>
      <PenListView locationId={locationId} data={data} />
      <div className={css.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAP_KEY }}
          onGoogleApiLoaded={onMapsLoaded}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={center}
          defaultZoom={15}
          options={options}
          onChildClick={selectDevice}
        >
          {markers}
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default PoleMap;
