import React from 'react';
import {
    Route,
    Navigate
} from 'react-router-dom';

import AdminLayout from '../layouts/AdminLayout';
import ProtectedLayout from '../layouts/ProtectedLayout';

import Customers from '../Admin/Customers';
import Customer from '../Admin/Customer';
import Location from '../Admin/Location';

// import Devices from '../Admin/Customers/Customer/Devices';
// import Poles from '../Admin/Customers/Customer/Poles';
// import MonitoringAreas from '../Admin/Customers/Customer/MonitoringAreas';

import SystemUsers from '../Admin/SystemUsers';
import GlobalUsers from '../Admin/GlobalUsers';
import LocalUsers from '../Admin/LocalUsers';

import { ADMIN_PATH } from '../constants';

const AdminRouter = (
    <>
        <Route
            path="/admin"
            element={<Navigate to="/admin/customers" replace />}>
        </Route>
        <Route element={<ProtectedLayout portalDefaultPath={ADMIN_PATH} />}>
            <Route path="admin" element={<AdminLayout />}>
                <Route
                    path="users"
                    element={<SystemUsers />}
                />
                <Route path="customers">
                    <Route path="" element={<Customers />} />
                    <Route path=":customerId">
                        <Route
                            path=""
                            element={<Customer />}
                        />
                        <Route
                            path="users"
                            element={<GlobalUsers />}
                        />
                        <Route path="locations">
                            <Route path=":locationId">
                                <Route path="" element={<Location />} />
                                <Route path="users" element={<LocalUsers />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Route>
    </>
);

export default AdminRouter;
