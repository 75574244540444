import React, { useState } from 'react';
import moment from 'moment';

import CustomSlider from '../../components/forms/CustomSlider';
import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import RoundNoteModal from './RoundNoteModal';
import PenCattleGraph from './PenCattleGraph';
import BunkGraph from './BunkGraph';

import css from './styles.module.scss';
import IconCarat from '../../icons/Carat';
import IconPencil from '../../icons/Pencil';
import IconGear from '../../icons/Gear';

function Details(props) {
  const {
    isDebugView,
    showIds,
    setShowIds,
    shouldDrawMovement,
    setShouldDrawMovement,
    shouldDrawHeadPos,
    setShouldDrawHeadPos,
    snapshot,
    locationId,
    prevDevice,
    nextDevice,
    data,
    refetch,
    noNextCamera,
    isLoading,
    isDevicesLoading,
    isError,
    error
  } = props;

  const [showRoundNoteModal, setShowRoundNoteModal] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);

  function createNewRoundNote() {
    setSelectedNote(null);
    setShowRoundNoteModal(true);
  }

  const toolEls = isDebugView
    ? <>
      <li className={css.toolContainer}>
        <CustomSlider
          label="Movement Tracking"
          value={shouldDrawMovement}
          onChange={(val) => setShouldDrawMovement(!shouldDrawMovement)}
        />
      </li>
      <li className={css.toolContainer}>
        <CustomSlider
          label="Head Posture"
          value={shouldDrawHeadPos}
          onChange={(val) => setShouldDrawHeadPos(!shouldDrawHeadPos)}
        />
      </li>
    </>
    : <li>None available</li>;

  const round_note = data?.monitoring_area.round_note;

  const oneDay = 1000 * 60 * 60 * 24;
  const fifteenDaysAgo = new Date(Date.now() - (oneDay * 15));
  const yesterday = new Date(Date.now() - oneDay);

  const weightData = (data?.weight_metrics || []).map((m) => ({
    ...m,
    data: m.data.toFixed(0)
  }));

  const weightAvg = data?.weight_metrics?.length
    ? data.weight_metrics.reduce((s, m) => s + m.data, 0)
      / data.weight_metrics.length
    : null;

  let roundNoteEl = null;
  if (isError) {
    roundNoteEl = <ErrorBox show={isError} error={error.message} />
  } else {
    let first_name = null;
    let last_name = null;

    if (round_note) {
      const user = round_note.created_by;
      first_name = user.first_name;
      last_name = user.last_name;
    }

    roundNoteEl = round_note
      ? (
        <div className={css.note}>
          {/* <div> */}
          <p className={css.name}>{first_name} {last_name}</p>
          <p>{moment.utc(round_note.created_at).local().format('M/D/YY, hh:mm A')}</p>
          {/* </div> */}
          <p className={css.n}>{round_note.description}</p>
        </div>
      )
      : (
        <div className={css.note}>
          <p><strong>No note</strong></p>
        </div>
      );
  }

  const snapshotBtn = isDebugView
    ? (
      <button
        className='btn btn-outline-primary'
        onClick={snapshot}
      >Take Snapshot</button>
    )
    : null;

  return (
    <>
      <Loader loading={isLoading}>
        <div className={css.content}>
          <div className={css.sec}>
            <PenCattleGraph
              header="Pen Weight"
              timeLabel="Last two weeks"
              graphLabel="lbs"
              valueKey="data"
              // avg={weightAvg}
              data={weightData}
              startDate={fifteenDaysAgo}
              endDate={yesterday}
            />
          </div>

          <div className={css.sec}>
            <BunkGraph
              header="Bunk Levels"
              data={data?.bunk_metrics?.data?.pctFull}
            />
          </div>

          <div className={css.sec}>
            <ul>
              <li className={css.toolContainer}>
                <CustomSlider
                  label="Show IDs"
                  value={showIds}
                  onChange={(val) => setShowIds(!showIds)}
                />
              </li>
            </ul>
          </div>

          {snapshotBtn}

          <div id={css.ai} className={css.sec}>
            <h3>
              <IconGear color='#FFFFFF' />
              <span>AI Suggestions</span>
            </h3>
            <ul>
              {toolEls}
            </ul>
          </div>

          <div className={css.sec}>
            <h3>
              <IconPencil color='#ffffff' />
              <span>Pen Rider Notes</span>
            </h3>
            {roundNoteEl}
            <button
              className="btn btn-outline-primary"
              onClick={createNewRoundNote}
            >
              Add Note
            </button>
          </div>
        </div>
      </Loader>
      <div className={css.actions}>
        <button
          className="btn btn-primary"
          onClick={prevDevice}
          disabled={isDevicesLoading || noNextCamera}
        >
          <IconCarat color='#ffffff' />
          Prev Pen (Camera)
        </button>
        <button
          className="btn btn-primary"
          onClick={nextDevice}
          disabled={isDevicesLoading || noNextCamera}
        >
          Next Pen (Camera)
          <IconCarat color='#ffffff' dir='right' />
        </button>
      </div>

      {showRoundNoteModal && (
        <RoundNoteModal
          showModal={showRoundNoteModal}
          setShowModal={setShowRoundNoteModal}
          selected={selectedNote}
          areaId={data?.monitoring_area_id}
          locationId={locationId}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default Details;
