import React, { useState } from 'react';
import moment from 'moment';

import CustomTable from '../../components/CustomTable';
import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import useQuery from '../../hooks/useQuery';

import Client from '../../apis/Client';

import NoteModal from './NoteModal';

import css from './styles.module.scss';

function getDayDelta(v) {
  const date = new Date(v);
  const now = new Date();
  const delta = Math.abs(now - date);
  return Math.ceil(delta / (1000 * 60 * 60 * 24));
}

const columnsInfo = [
    {
        name: 'name',
        label: 'ID',
        width: 50
    },
    {
        name: 'monitoring_area',
        label: 'Pen',
        valFn: v => v.monitoring_area.name
    },
    {
        name: 'priority',
        label: 'Priority',
        valFn: v => {
          const note = v.health_note;
          return (note?.priority || '--').capitalize();
        },
        width: 50
    },
    {
        name: 'description',
        label: 'Reason',
        valFn: v => {
          const note = v.health_note;
          return note?.description  || '--';
        }
    },
    {
        name: 'lastId',
        label: 'Last ID',
        valFn: (v) => moment.utc(v.updated_at).local().format('M/D/YY hh:mm A')
    },
    {
        name: 'lotArrival',
        label: 'Lot Arrival',
        valFn: (v) => moment.utc(v.created_at).local().format('M/D/YY')
    },
    {
        name: 'daysInLot',
        label: 'Days in Lot',
        valFn: (v) => getDayDelta(v.created_at)
    },
    {
        name: 'penArrival',
        label: 'Pen Arrival',
        valFn: (v) => moment.utc(v.created_at).local().format('M/D/YY')
    },
    {
        name: 'daysInPen',
        label: 'Days in Pen',
        valFn: (v) => getDayDelta(v.created_at)
    },
    // {
    //     name: 'vetCheck',
    //     label: 'Recent Vet Check',
    // },
    // {
    //     name: 'vet_notes',
    //     label: '',
    //     componentFn: (v) => {
    //       if (v.lastVetCheck) {
    //         return <button>View Vet Notes</button>;
    //       }

    //       return null;
    //     }
    // },
    {
        name: 'click_icon',
        label: '',
        componentFn: () => {
          return <div></div>
        }
    }
];

function NoteTable(props) {
  const { locationId } = props;

  const url = `/api/v1/producers/locations/${locationId}/cattle?note_open=true`;

  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['get_dashboard_cattle', locationId],
    queryFn: () => Client.get(url)
  });

  const [priorityFilter, setPriorityFilter] = useState(null);
  const [search, setSearch] = useState('');
  const [note, setNote] = useState(null);
  const [showNote, setShowNote] = useState(false);

  const filteredData = (data || [])
    .filter((v) => {
      if (!priorityFilter) {
        return v;
      }

      return v.health_note.cattle_note_type === priorityFilter;
    })
    .filter((v) => {
      if (!search) return true;

      const rxp = new RegExp(`^${search}`);
      return rxp.test(v.name) || rxp.test(v.monitoring_area.name);
    });

  if (isError) {
    return <ErrorBox show={isError} message={error.message} />;
  }

  return (
    <Loader loading={isLoading}>
      <div className={css.tableContainer}>
        <div className={css.filterRow} style={{ flexWrap: 'wrap' }}>
          <div className={`${css.filterRowItem} ${css.vert}`}>
            <h3>Priority</h3>
            <div className={css.btnGroup}>
              <button
                onClick={() => setPriorityFilter(null)}
                className={priorityFilter === null ? css.active : ''}
              >All</button>
              <button
                onClick={() => setPriorityFilter('watch')}
                className={priorityFilter === 'watch' ? css.active : ''}
              >
                <div className={css.dot} />
                Watch
              </button>
              <button
                onClick={() => setPriorityFilter('pull')}
                className={priorityFilter === 'pull' ? css.active : ''}
              >
                <div className={`${css.dot} ${css.yellow}`} />
                Pull
              </button>
              {/*<button
                onClick={() => setPriorityFilter('vet')}
                className={priorityFilter === 'vet' ? css.active : ''}
              >
                <div className={`${css.dot} ${css.red}`} />
                Vet Check
              </button>*/}
            </div>
          </div>

          <div
            className={css.filterRowItem}
            style={{ flex: 1 }}
          >
            <div className={css.searchBlock}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.3359 9.77506L13.7871 13.4229C14.0791 13.7316 14.0694 14.2236 13.766 14.5203C13.6232 14.66 13.4351 14.7368 13.2368 14.7368C13.0273 14.7368 12.8318 14.6522 12.6864 14.4988L9.20899 10.8233C8.22158 11.5285 7.06667 11.9 5.85474 11.9C2.62649 11.9 0 9.23077 0 5.94999C0 2.66922 2.62649 0 5.85474 0C9.08299 0 11.7095 2.66922 11.7095 5.94999C11.7095 7.35419 11.2233 8.70303 10.3359 9.77506ZM10.3158 5.89474C10.3158 3.45692 8.33256 1.47368 5.89474 1.47368C3.45692 1.47368 1.47368 3.45692 1.47368 5.89474C1.47368 8.33256 3.45692 10.3158 5.89474 10.3158C8.33256 10.3158 10.3158 8.33256 10.3158 5.89474Z" fill="#29313B"/>
              </svg>
              <input
                value={search}
                onChange={e => setSearch(e.target.value)} />
            </div>
          </div>
        </div>

        <NoteModal
          note={note}
          showNote={showNote}
          setShowNote={setShowNote}
        />

        <div style={{ width: '100%', height: 'calc(100vh - 428px)' }}>
          <CustomTable
            columns={columnsInfo}
            rows={filteredData}
            rowKey="id"
            defaultSortCol="id"
            onRowClick={(event, row) => {
              setNote(row.health_note);
              setShowNote(true);
            }}
          />
        </div>
      </div>
    </Loader>
  );
}

export default NoteTable;
