import React, { useState } from 'react';

import CustomTable from '../../components/CustomTable';
import Loader from '../../components/Loader';

import css from './styles.module.scss';

const mugStyle = { maxHeight: '64px', objectFit: 'contain', objectPosition: 'left', width: '100%' };

const columnsInfo = [
  // {
  //   name: 'mug',
  //   label: 'Mug',
  //   componentFn: (head) => {
  //     if (head.mug_url) {
  //       return <img src={head.mug_url} alt="cow mug" style={mugStyle}></img>
  //     }

  //     return '--'
  //   },
  //   width: 130,//'14%!important',
  // },
  {
    name: 'name',
    label: 'ID',
    valFn: (head) => head.name,
    // width: 75,//'8%!important',//50
  },
  {
    name: 'note',
    label: 'Health Note',
    valFn: (head) => (head.health_note?.cattle_note_type || '--').capitalize(),
    // width: 75,//50
  },
  {
    name: 'priority',
    label: 'Priority',
    valFn: (head) => {
      return (head.health_note?.priority || '--').capitalize();
    },
    // width: 50
  },
  {
    name: 'description',
    label: 'Reason',
    valFn: (head) => {
      const note = head.health_note;
      return note?.description || '--';
    }
  },
  // {
  //   name: 'vetCheck',
  //   label: 'Recent Vet Check',
  //   // width: 110,
  // },
  {
    name: 'mug_rec_counter',
    label: 'Rec Count'
  },
  // {
  //   name: 'vet_notes',
  //   label: '',
  //   componentFn: (note) => {
  //     if (note.lastVetCheck) {
  //       return <button className='btn btn-outline-primary'>View Vet Notes</button>;
  //     }

  //     return null;
  //   },
  //   // width: 130,
  // },
];

function ExpandTable(props, ref) {
  const { data, setSelected, show, setShow, isLoading, isDebugView } = props;

  const [priorityFilter, setPriorityFilter] = useState(null);
  const [search, setSearch] = useState('');

  if (!show) {
    return null;
  }

  const filteredData = (data || [])
    .filter((head) => {
      if (!priorityFilter) {
        return head;
      }

      const note = head.health_note;

      if (!note?.cattle_note_type) {
        return false;
      }

      return note.cattle_note_type === priorityFilter;
    })
    .filter((head) => {
      if (search) {
        const regex = new RegExp(`^${search}`);
        return regex.test(head.name);
      }

      return true;
    });

  const columns = [...columnsInfo];
  if (true) {
    columns.push({
      name: 'mug_debug',
      label: 'Mug Debug',
      componentFn: (head) => {
        if (head.mug_debug) {
          return <img src={head.mug_debug.url} alt="cow mug" style={mugStyle}></img>
        }

        return '--'
      }
    });
  }

  return (
    <div className={css.expandContainer}>
      <div className={css.header}>
        <h3>Herd Manager</h3>
        <button onClick={() => setShow(false)}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1341 9.41004L12 10.2721L10.2721 12L9.41004 11.138L6 7.72791L2.58996 11.138L1.72791 12L0 10.2721L0.862047 9.41004L4.27209 6L0.865861 2.58996L0 1.72791L1.72791 9.53674e-07L2.58996 0.862046L6 4.27209L9.41004 0.862046L10.2721 9.53674e-07L12 1.72791L11.138 2.58996L7.72791 6L11.138 9.41004H11.1341Z" fill="white"/>
          </svg>
        </button>
      </div>

      <div className={css.filterRow}>
        <div className={css.statusFilters}>
          <button
            onClick={() => setPriorityFilter(null)}
            className={priorityFilter === null ? css.active : ''}
          >All ({data.length})</button>
          <button
            onClick={() => setPriorityFilter('watch')}
            className={priorityFilter === 'watch' ? css.active : ''}
          >
            <div className={`${css.dot} ${css.gray}`} />
            Watch
            </button>
          <button
            onClick={() => setPriorityFilter('pull')}
            className={priorityFilter === 'pull' ? css.active : ''}
          >
            <div className={`${css.dot} ${css.yellow}`} />
            Pull
          </button>
          {/*<button
            onClick={() => setPriorityFilter('vet')}
            className={priorityFilter === 'vet' ? css.active : ''}
          >
            <div className={`${css.dot} ${css.red}`} />
            Vet Check
          </button>*/}
        </div>

        <div className={`${css.filterRowItem} ${css.search}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3359 9.77506L13.7871 13.4229C14.0791 13.7316 14.0694 14.2236 13.766 14.5203C13.6232 14.66 13.4351 14.7368 13.2368 14.7368C13.0273 14.7368 12.8318 14.6522 12.6864 14.4988L9.20899 10.8233C8.22158 11.5285 7.06667 11.9 5.85474 11.9C2.62649 11.9 0 9.23077 0 5.94999C0 2.66922 2.62649 0 5.85474 0C9.08299 0 11.7095 2.66922 11.7095 5.94999C11.7095 7.35419 11.2233 8.70303 10.3359 9.77506ZM10.3158 5.89474C10.3158 3.45692 8.33256 1.47368 5.89474 1.47368C3.45692 1.47368 1.47368 3.45692 1.47368 5.89474C1.47368 8.33256 3.45692 10.3158 5.89474 10.3158C8.33256 10.3158 10.3158 8.33256 10.3158 5.89474Z" fill="#383838"/>
          </svg>
          <input value={search} onChange={e => setSearch(e.target.value)} />
        </div>
      </div>

      <div style={{
        paddingRight: 20,
        paddingLeft: 20,
        width: '100%',
        height: '100%'
      }}>
        <Loader loading={isLoading}>
          <CustomTable
            columns={columns}
            rows={filteredData}
            rowKey="id"
            defaultSortCol="name"
            rowHeight={80}
            onRowClick={(event, row) => {
              setSelected(row.id)
            }}
          />
        </Loader>
      </div>
    </div>
  );
}

export default ExpandTable;
