import React, {useContext} from 'react';
import {Formik, Form} from 'formik';
import {useMutation} from '@tanstack/react-query';
import css from './styles.module.scss';
import PaneInput from './PaneInput';
import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import ErrorBox from '../components/ErrorBox';

import {required, email, password} from '../validation/form';

import Client from '../apis/Client';

import AuthContext from '../context/AuthContext';

const validate = (values) => {
    const errors = {};

    required(errors, values, 'email');
    required(errors, values, 'password');

    password(errors, values, 'password');

    email(errors, values, 'email');

    return errors;
};

const initialValues = {
    email: '',
    password: '',
};

const PaneLogin = (props) => {
    const {setFlow, setShowForgot} = props;

    const {login} = useContext(AuthContext);

    const {
        mutateAsync: onSubmit,
        error,
        isError,
        isLoading,
    } = useMutation({
        mutationFn: async (data) => Client.post('/api/v1/auth/login', data),
        onSuccess: (data) => {
            login(data);
        },
    });

    return (
        <div className={css.paneInner}>
            <div className={css?.header}>
                <div className={css.logo}>
                    <div className={css.logoIcons}>
                        <span>
                            <LogoHorns fill="#29313B" />
                        </span>
                        {/*<span>
                            <LogoCattle fill="#29313B" />
                        </span>
                        <span>
                            <LogoPig fill="#29313B" />
                        </span>*/}
                    </div>
                </div>

                <div className={css?.copy}>
                    <h1>Login to Herd Sense</h1>

                    <p>
                        Welcome. Please enter your login information below to
                        enter.
                        {/*or{' '}
                <a
                    href="#signup"
                    onClick={(e) => {
                        e.preventDefault();
                        setFlow('create');
                    }}
                >
                    click here
                </a>{' '}
                to register.*/}
                    </p>
                </div>
            </div>

            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {(formik) => (
                    <Form
                        id={css.loginForm}
                        className={css.form}
                        onSubmit={formik.handleSubmit}>
                        <PaneInput
                            className={css.formEl}
                            name="email"
                            label="Email"
                            placeholder="your email here"
                            required
                            formik={formik}
                        />

                        <PaneInput
                            className={css.formEl}
                            name="password"
                            label="Password"
                            placeholder="password"
                            password
                            required
                            type="password"
                            formik={formik}
                        />

                        <ErrorBox show={isError} message={error?.message} />

                        <div className={css.actions}>
                            <button
                                type="button"
                                className={`btn btn-link`}
                                onClick={() => setShowForgot(true)}
                                style={{fontSize: '1.2rem'}}>
                                Forgot Password?
                            </button>

                            <button
                                onClick={formik.handleSubmit}
                                type="submit"
                                className={`btn btn-primary ${
                                    isLoading ? 'btn-disabled' : 'btn-primary'
                                }`}
                                disabled={
                                    isLoading ||
                                    Object.keys(formik.errors).length
                                }>
                                Login
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PaneLogin;
