import React from 'react'

const IconTag = props => {

    const {
        title = '',
        color = '#ffffff',
    } = props

    return (
        <>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill={color}/>
<path d="M20 20H20.01M16.5627 14.9373L14.9373 16.5627C14.5914 16.9086 14.4184 17.0816 14.2947 17.2834C14.1851 17.4624 14.1043 17.6575 14.0553 17.8615C14 18.0917 14 18.3363 14 18.8255L14 21.6745C14 22.1637 14 22.4083 14.0553 22.6385C14.1043 22.8425 14.1851 23.0376 14.2947 23.2166C14.4184 23.4184 14.5914 23.5914 14.9373 23.9373L22.6059 31.6059C23.7939 32.7939 24.388 33.388 25.0729 33.6105C25.6755 33.8063 26.3245 33.8063 26.927 33.6105C27.612 33.388 28.2061 32.7939 29.3941 31.6059L31.6059 29.3941C32.7939 28.2061 33.388 27.612 33.6105 26.927C33.8063 26.3245 33.8063 25.6755 33.6105 25.0729C33.388 24.388 32.7939 23.7939 31.6059 22.6059L23.9373 14.9373C23.5914 14.5914 23.4184 14.4184 23.2166 14.2947C23.0376 14.1851 22.8425 14.1043 22.6385 14.0553C22.4083 14 22.1637 14 21.6745 14L18.8255 14C18.3363 14 18.0917 14 17.8615 14.0553C17.6575 14.1043 17.4624 14.1851 17.2834 14.2947C17.0816 14.4184 16.9086 14.5914 16.5627 14.9373ZM20.5 20C20.5 20.2761 20.2761 20.5 20 20.5C19.7239 20.5 19.5 20.2761 19.5 20C19.5 19.7239 19.7239 19.5 20 19.5C20.2761 19.5 20.5 19.7239 20.5 20Z" stroke="#3E9CDB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

            {title && <h4><span>{title}</span></h4>}
        </>
    );
}

export default IconTag;