import React, { useState, useRef } from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';

import Client from '../../apis/Client';

import useMutation from '../../hooks/useMutation';
import useQuery from '../../hooks/useQuery';

import Loader from '../../components/Loader/Loader';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import CustomTable from '../../components/CustomTable';
import AlertModal from '../../components/AlertModal';

import UserModal from './UserModal';

import css from '../styles.module.scss';

//const devicesPolesPens = ''

export default function GlobalUsers() {
    const { customerId, locationId } = useParams();

    const navigate = useNavigate();

    const [showUserModal, setShowUserModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const activeUser = useRef({});

    const { isLoading, isError, error, data, refetch } = useQuery({
        queryFn: () => Client.get(`/api/v1/admin/locations/${locationId}/users`),
        queryKey: ['location_users', locationId]
    });

    const { mutate: removeUser, isLoading: removeUserIsLoading } =
    useMutation({
        mutationKey: ['location_users_remove'],
        mutationFn: () => {
            return Client.delete(`/api/v1/admin/locations/${locationId}/users/${activeUser.current.id}`);
        },
        onSuccess: () => {
            refetch();
            setShowDeleteModal(false);
        }
    });

    if (isError) {
        return <h4 style={{ color: 'red' }}>{error.msg}</h4>;
    }

    if (isLoading) {
        return <Loader></Loader>;
    }

    const columnsInfo = [
        {
            name: 'name',
            label: 'User Name',
            valFn: (datum) => {
                return `${datum.user.first_name} ${datum.user.last_name}`;
            },
        },
        {
            name: 'phone',
            label: 'Phone',
            valFn: (datum) => {
                return datum.user.phone;
            },
        },
        {
            name: 'email',
            label: 'Email',
            valFn: (datum) => {
                return datum.user.email;
            },
        },
        {
            name: 'created_at',
            label: 'Date Joined',
            valFn: (datum) => {
                return datum.is_invite ? 'Pending' : datum.created_at;
            },
        },
        {
            name: 'status',
            label: 'Status',
        },
        {
            name: 'action-1',
            label: '',
            componentFn: (user) => (
                <button
                    type="button"
                    className='btn btn-danger'
                    onClick={(e) => {
                        e.stopPropagation();
                        activeUser.current = { ...user };
                        setShowDeleteModal(true);
                    }}
                >
                    Remove
                </button>
            )
        }
    ];

    return (
        <>
            <div id={css.glActions}>
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/admin/customers')}>
                    Back
                </button>
            </div>

            <div id={css.overview} className="tableContain">
                <div id={css.titleLocation}>
                    <PageSubTitle title="Location Users" />
                </div>
            </div>

            <div className={`bgWhite tableContain`}>
                <div style={{ width: '100%', height: 'calc(100vh - 380px)' }}>
                    <CustomTable
                        rows={data}
                        columns={columnsInfo}
                    />
                </div>
                <div id={css.actionsBlock} className='actions'>
                    <button
                        type="button"
                        className='btn btn-primary'
                        onClick={() => {
                            activeUser.current = {};
                            setShowUserModal(true);
                        }}>
                        Add New User
                    </button>
                </div>
            </div>

            {showUserModal && (
                <UserModal
                    setShowModal={setShowUserModal}
                    showModal={showUserModal}
                    user={activeUser.current}
                    companyId={customerId}
                    locationId={locationId}
                    refetch={refetch}
                />
            )}

            <AlertModal
                show={showDeleteModal}
                onAccept={removeUser}
                onCancel={() => setShowDeleteModal(false)}
                title="Remove User"
                btn1="No"
                btn2="Yes"
                message="Would you like to remove user?"
            />
        </>
    );
}
