import React from 'react'

const IconHeadHanging = props => {

    const {
        title = '',
        color = '#ffffff',
    } = props

    return (
        <>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill={color} />
                <path d="M25 17C25 16.4477 24.5523 16 24 16C23.4477 16 23 16.4477 23 17V28.5858L17.7071 23.2929C17.3166 22.9024 16.6834 22.9024 16.2929 23.2929C15.9024 23.6834 15.9024 24.3166 16.2929 24.7071L23.2929 31.7071C23.6834 32.0976 24.3166 32.0976 24.7071 31.7071L31.7071 24.7071C32.0976 24.3166 32.0976 23.6834 31.7071 23.2929C31.3166 22.9024 30.6834 22.9024 30.2929 23.2929L25 28.5858V17Z" fill="#3E9CDB" />
            </svg>

            {title && <h4><span>{title}</span></h4>}
        </>
    );
}

export default IconHeadHanging;