import React from 'react';

const IconInstantAlerts = (props) => {
    const {title = '', color = '#ffffff'} = props;

    return (
        <>
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
                    fill="#D8ECF8"
                />
                <path
                    d="M24 20V24M24 28H24.01M34 24C34 29.5228 29.5228 34 24 34C18.4772 34 14 29.5228 14 24C14 18.4772 18.4772 14 24 14C29.5228 14 34 18.4772 34 24Z"
                    stroke="#3E9CDB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            {title && (
                <h4>
                    <span>{title}</span>
                </h4>
            )}
        </>
    );
};

export default IconInstantAlerts;
