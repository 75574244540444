import React from 'react'

const IconCommunication = props => {

  const {
    title = '',
    color = '#3099FD',
  } = props

  return (
    <>
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dii_7751_139)">
<path d="M2 11C2 5.47715 6.47715 1 12 1H40C45.5229 1 50 5.47715 50 11V39C50 44.5229 45.5228 49 40 49H12C6.47715 49 2 44.5228 2 39V11Z" fill="#475467"/>
<path d="M3 11C3 6.02944 7.02944 2 12 2H40C44.9706 2 49 6.02944 49 11V39C49 43.9706 44.9706 48 40 48H12C7.02944 48 3 43.9706 3 39V11Z" stroke="url(#paint0_linear_7751_139)" stroke-width="2"/>
<path d="M24 28L20.9247 31.1137C20.4958 31.548 20.2813 31.7652 20.097 31.7805C19.937 31.7938 19.7804 31.7295 19.676 31.6076C19.5556 31.4672 19.5556 31.162 19.5556 30.5515V28.9916C19.5556 28.444 19.1071 28.0477 18.5652 27.9683V27.9683C17.2537 27.7762 16.2238 26.7463 16.0317 25.4348C16 25.2186 16 24.9605 16 24.4444V19.8C16 18.1198 16 17.2798 16.327 16.638C16.6146 16.0735 17.0735 15.6146 17.638 15.327C18.2798 15 19.1198 15 20.8 15H28.2C29.8802 15 30.7202 15 31.362 15.327C31.9265 15.6146 32.3854 16.0735 32.673 16.638C33 17.2798 33 18.1198 33 19.8V24M33 35L30.8236 33.4869C30.5177 33.2742 30.3647 33.1678 30.1982 33.0924C30.0504 33.0255 29.8951 32.9768 29.7356 32.9474C29.5558 32.9143 29.3695 32.9143 28.9969 32.9143H27.2C26.0799 32.9143 25.5198 32.9143 25.092 32.6963C24.7157 32.5046 24.4097 32.1986 24.218 31.8223C24 31.3944 24 30.8344 24 29.7143V27.2C24 26.0799 24 25.5198 24.218 25.092C24.4097 24.7157 24.7157 24.4097 25.092 24.218C25.5198 24 26.0799 24 27.2 24H32.8C33.9201 24 34.4802 24 34.908 24.218C35.2843 24.4097 35.5903 24.7157 35.782 25.092C36 25.5198 36 26.0799 36 27.2V29.9143C36 30.8462 36 31.3121 35.8478 31.6797C35.6448 32.1697 35.2554 32.5591 34.7654 32.762C34.3978 32.9143 33.9319 32.9143 33 32.9143V35Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_dii_7751_139" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7751_139"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7751_139" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_7751_139"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_innerShadow_7751_139"/>
<feOffset/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.18 0"/>
<feBlend mode="normal" in2="effect2_innerShadow_7751_139" result="effect3_innerShadow_7751_139"/>
</filter>
<linearGradient id="paint0_linear_7751_139" x1="26" y1="1" x2="26" y2="49" gradientUnits="userSpaceOnUse">
<stop stop-color="white" stop-opacity="0.12"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

      {title && <h4><span>{title}</span></h4>}
    </>
  );
}

export default IconCommunication;