import React, { createElement } from "react";

import { icons } from "./Icons.js";

const Icon = ({
  icon,
  // isOpen,
  // fill = null,
  // stroke = null,
}) => (
  <div>{createElement(icons[icon])}</div>
);

export default Icon;
