import React from 'react';
import PropTypes from 'prop-types';
import css from './styles.module.scss';

const HomeButton = ({ children, onClick, ghost = false }) => {
  const buttonClass = ghost ? css.ghostButton : css.regularButton;

  return (
    <button className={`${css.button} ${buttonClass}`} onClick={onClick}>
      {children}
    </button>
  );
};

HomeButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  ghost: PropTypes.bool,
};

export default HomeButton;