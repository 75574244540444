import { useLocation, useSearchParams, Link } from 'react-router-dom';

import LogoHorns from '../../icons/LogoHorns';

import css from './styles.module.scss';
import MobTrigger from '../MobTrigger/MobTrigger';
import Icon from '../Icon/Icon';

export default function TopHeader(props) {
  const {
    links,
    scrollableLinks,
    scrollableWidth = 'auto',
    label,
    query = '',
    customEls,
    navOpen,
    setNavOpen,
    blank,
    homeLink = '/',
    onCancel = () => {}
  } = props;

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const mapLinks = (link) => {
    const linkQueryEntries = Object.entries(link?.queryParams || {});
    const linkQueryString = linkQueryEntries.length
      ? `${linkQueryEntries.map(([k, v]) => `${k}=${v}`).join('&')}`
      : '';

    let queryString = query;
    if (queryString && linkQueryString) {
      queryString += `&${linkQueryString}`;
    } else if (!queryString && linkQueryString) {
      queryString += `?${linkQueryString}`;
    }

    const url = `${link.path}${queryString}`;

    const isActive = linkQueryEntries.length
      ? linkQueryEntries.every(([k, v]) => searchParams.get(k) === v)
      : location.pathname === link.path;

    const handleCancel = (e) => {
      e.preventDefault();
      onCancel(link);
    };

    const cancelBtn = link.cancelable
      ? (
        <div className={css.cancelBtn} onClick={handleCancel}>
          <Icon icon="X" />
        </div>
      )
      : null;

    return (
      <li key={url} className={isActive ? css.active : ''}>
        <Link to={`${link.path}${queryString}`}>
          {link.title}
          {cancelBtn}
        </Link>
      </li>
    )
  };

  const linkEls = links?.map(mapLinks);
  const scrollableLinkEls = scrollableLinks?.map(mapLinks);

  if (blank === true) {
    return <div id={css.header} />
  }

  return (
    <div id={css.header}>
      <MobTrigger navOpen={navOpen} setNavOpen={setNavOpen} />

      <Link to={homeLink} className={css.logoHolder}>
        <div className={css.logoIcons}>
          <span>
            <LogoHorns />
          </span>
        </div>
        <span>{label}</span>
      </Link>

      <nav id={css.nav} className={navOpen ? css.opened : ''}>
        <ul>
          {linkEls}
        </ul>
        {scrollableLinks?.length > 0 && (
          <ul className={css.scrollable}>
            {scrollableLinkEls}
          </ul>
        )}
      </nav>
      
      <div className={`${css.settingsBlock} ${navOpen ? css.opened : ''}`}>
        {customEls}
      </div>
    </div>
  );
}
