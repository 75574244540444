import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';


// images
import imgAnalytics from '../images/analytics.jpg'
import imgEcosystems from '../images/ecosystems.jpg'
import imgGrow from '../images/grow.jpg'
import imgHero from '../images/hero-bg.png'
import imgHeroM from '../images/hero.jpg'
import imgInTheKnow from '../images/in-the-know.jpg'
import imgAiDriven from '../images/ai-driven-cattle.jpg'
import imgScience from '../images/science-cattle.jpg'
import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import css from './styles.module.scss'

import HomeHero from './Components/HomeHero/HomeHero'
import HomeCard from './Components/HomeCard/HomeCard'
import HomeButton from './Components/Button/HomeButton'
import LogoBlock from '../components/LogoBlock/LogoBlock'
import IconAI from '../icons/AI'
import IconCamera from '../icons/Camera'
import IconSecurity from '../icons/Security'
import IconPrivacy from '../icons/Privacy'
import IconBilling from '../icons/Billing'
import IconHardware from '../icons/Hardware'
import IconConnectivity from '../icons/Connectivity'
import IconHealth from '../icons/Health'
import IconAnalytics from '../icons/Analytics'
import IconCompliance from '../icons/Compliance'
import IconCart from '../icons/Cart'
import IconProfit from '../icons/Profit'
import IconAlerts from '../icons/Alerts'
import IconTracking from '../icons/Tracking'
import IconIdentification from '../icons/Identification'
import IconHeadHanging from '../icons/HeadHanging'
import IconTag from '../icons/Tag'
import IconLimping from '../icons/Limping'
import Home from './Home'

const HomeContent = ({ title = '', content = '', classes = 'col-12' }) => {
  return (
    <div className={`d-flex flex-column position-relative ${classes} ${css.homeContent} ${css.prod}`} style={{ zIndex: 10 }}>
      {title && <h2 className={`mb-5`}>{title}</h2>}
      {content}
    </div>
  )
}

const Category = () => {
  const cats = [
    {
      icon: <IconHealth bgcolor='#3099FD' />,
      title: 'Health & Safety',
      para: `Applications focused on the well being and health of the animals`
    },
    {
      icon: <IconAnalytics bgcolor='#3099FD' />,
      title: 'Analytics',
      para: `Tracks information about the animals to derive deeper insights to drive higher ROI on each animal`
    },
    {
      icon: <IconCompliance bgcolor='#3099FD' />,
      title: 'Operations',
      para: `Applications focused on ensuring environmental, employee or lot compliance`
    },
  ]

  const catEls = cats.map(cat => {
    return (
      <div key={cat.title} className={`align-items-center d-flex flex-column justify-content-start ${css.card}`}>
        {cat.icon}
        <h3 className={`mb-2`}>{cat.title}</h3>
        <p className={`text-center`}>{cat.para}</p>
      </div>
    )
  })

  return (
    <div className={`row align-items-start d-flex flex-column flex-md-row justify-content-md-between g-0 ${css.holder}`}>
      {catEls}
    </div>
  )
}

const HomeProducers = () => {
  const formRef = useRef(null)
  const [message, setMessage] = useState('');

  const scriptUrl = "https://script.google.com/macros/s/AKfycbx8AOqBZmTY4MwF-p0rEgcUNX2LPyn8xpH-q2MuGT-luLBrU1FkyDSeF9hDd_ng97n7aQ/exec"

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const formData = new FormData(formRef.current);
    formData.append('sheetName', 'Notify');
    const email = formData.get('email');

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      //alert('Please enter a valid email address.');
      setMessage('Please enter a valid email address.');
      return;
    }

    fetch(scriptUrl, { method: 'POST', body: formData })
      .then((res) => {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return res.json();
        } else {
          return res.text();
        }
      })
      .then((data) => {
        setMessage(typeof data === 'string' ? data : JSON.stringify(data));
      })
      .catch((error) => {
        console.log(error);
        setMessage('An error occurred. Please try again.');
      });
  };

  return (
    <>

      <section className={`container d-flex flex-column ${css.container}`}>
        <HomeHero
          h1={<>Protect Your Herd.<br></br>Protect Your Investment.</>}
          sub={`HerdSense™ changes the game in herd health management and can save you thousands of dollars and prevent costly long-term consequences. With AI-powered monitoring, HerdSense is here to help you catch health problems before they escalate, ensuring healthier cattle and more efficient operations. `}
          img={imgHero}
          imgm={imgHeroM} />


        <HomeCard id='apps'>
          <HomeContent
            title='AI-driven monitoring for your entire herd'
            content={
              <>
                <p>HerdSense uses advanced AI to monitor cattle behavior and health 24/7. The system tracks essential health metrics like time spent eating, drinking, and moving. When any deviations from normal behavior are detected, alerts are sent in real time, enabling you to take quick action and prevent bigger issues.</p>
              </>
            }
            classes='col-12 col-md-8' />
        </HomeCard>
        <HomeCard>
          <div className="align-items-end d-flex flex-row flex-wrap" style={{ width: '100%' }}>
            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-evenly" style={{ height: '476px' }}>
              <div className="d-flex align-items-center">
                <div className={`${css.iconContainer}`}>
                  <IconAlerts title='' bgcolor='#ffffff' />
                </div>
                <div className={`${css.textContainer}`}>
                  <h3>Real-time alerts</h3>
                  <p>Receive immediate notifications when cattle behavior indicates stress, illness, or injury.</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className={`${css.iconContainer}`}>
                  <IconTracking title='' bgcolor='#3099FD' />
                </div>
                <div className={`${css.textContainer}`}>
                  <h3>Health tracking</h3>
                  <p>Track critical indicators such as feed intake, water consumption, and movement patterns, helping to catch issues early.</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className={`${css.iconContainer}`}>
                  <IconIdentification title='' bgcolor='#3099FD' />
                </div>
                <div className={`${css.textContainer}`}>
                  <h3>Non-invasive identification</h3>
                  <p>Our non-touch ID system reduces stress during animal relocation, promoting calmness and herd health.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12" style={{ flex: '1 1 50%', backgroundImage: `url(${imgAiDriven})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '414px', marginBottom: '35px' }}>
              {/* Additional content can go here if needed */}
            </div>
          </div>
        </HomeCard>

        <HomeCard id='monitors'>
          <div className={`col-lg-12 ${css.monitors}`}>
            <div className={`${css.contentContainer}`}>
              <div className={`${css.textContainer}`}>
                <h4>Features</h4>
                <h3>What HerdSense monitors</h3>
                <p>HerdSense is designed to detect subtle changes in your cattle’s health and behavior, providing early alerts for faster, more effective interventions.</p>
              </div>
              <div className={`d-flex flex-wrap ${css.iconBoxesContainer}`}>
                <div className={`${css.iconBox} d-flex align-items-center`}>
                  <IconTag title='' bgcolor='#ffffff' />
                  <div className={css.textContainer}>
                    <h3>Livestock identification</h3>
                    <p>Non-touch identification tracks individual animals without stress, ensuring accurate monitoring for each member of your herd.</p>
                  </div>
                </div>
                <div className={`${css.iconBox} d-flex align-items-center`}>
                  <IconHeadHanging title='' bgcolor='#3099FD' />
                  <div className={css.textContainer}>
                    <h3>Head hanging</h3>
                    <p>Detects unusual head positions, a key indicator of potential respiratory or neurological issues.</p>
                  </div>
                </div>
                <div className={`${css.iconBox} d-flex align-items-center`}>
                  <IconLimping title='' bgcolor='#3099FD' />
                  <div className={css.textContainer}>
                    <h3>Limping</h3>
                    <p>Analyzes gait and movement to identify lameness, injury, or discomfort before it impacts productivity.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HomeCard>
        <HomeCard id='science'>
          <div className="align-items-end d-flex flex-row flex-wrap" style={{ width: '100%' }}>
            <div className="col-lg-6 col-md-12  justify-content-evenly" style={{ height: '100%' }}>
              <div className={css.textContainer}>
                <h3>Backed by science, built for ranchers</h3>
                <p>HerdSense blends cutting-edge technology with decades of agricultural expertise. Whether you manage a small operation or a massive feedlot, HerdSense is your guardian of herd health giving you the tools to monitor cattle health, detect issues early, and improve herd performance.</p>
              </div>
              <div className={css.textContainer}>
                <h4 className='pb-4'>The costs of inaction</h4>
                <p className={css.small}>Failing to catch health issues early costs ranchers more than just time – it cuts into profits. Consider these statistics:
                </p>
                <div className={css.small}>
                  <ul>
                    <li>15-20% of feedlot cattle are impacted by BRDC annually, contributing to lost weight gain, increased medical costs, and higher mortality rates.</li>
                    <li>Footrot and lameness result in losses ranging from $30-$50 per cow annually, affecting weight gain and productivity.</li>
                    <li>Transportation stress and environmental changes can reduce average daily gain by up to 13 pounds per animal, leading to significant losses.</li>
                    <li>Early intervention improves recovery rates by 70%, preventing prolonged illnesses and loss of productivity.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-5 " style={{ flex: '1 1 50%', backgroundImage: `url(${imgScience})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '650px' }}>
              {/* Additional content can go here if needed */}
            </div>
          </div>
        </HomeCard>
        <HomeCard id='requestdemo'>
          <div className="row" style={{ height: '1px', width: '100%', backgroundColor: '#E4E7EC', margin: '15px 0 50px 0' }}></div>
          <HomeContent
            title='Ready to protect your herd?'
            content={
              <>
                <p>Get started today and see the difference HerdSense can make. Whether you manage a small operation or a large feedlot, HerdSense gives you the tools to monitor and maintain the health of your herd, reducing costs and improving productivity.</p>
                <h4>Contact us for a demo</h4>
                <p>Find out how HerdSense can help you improve herd health and maximize profitability.</p>
                <Link to="/request-demo" onClick={() => window.scrollTo(0, 0)}>
                  <HomeButton className={`${css.animatedText} ${css.cbutton}`}>Request a demo</HomeButton>
                </Link>
              </>
            }
            classes='col-12 col-md-8' />
        </HomeCard>
        <HomeCard id='getNotified'>
          <div className="row">
            <div className="col-12 col-md-8">
              <HomeContent
                title='Get notified when we launch'
                content={
                  <>
                    <p>HerdSense is currently in a pilot trial phase and is not yet available for commercial purchase. Our innovative approach to herd health management is being evaluated to ensure it meets the high standards you expect.</p>
                    <p>Want to be the first to know when HerdSense becomes available? Enter your email, and we’ll keep you updated.</p>
                    <p>For any immediate questions, feel free to reach out to us at info@herdsense.com.</p>
                  </>
                }
                classes='col-12'
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-start flex-column">
              <form ref={formRef} onSubmit={handleSubmitForm} className="form w-100 d-flex flex-column flex-md-row">
                <input type="email" name='email' className="form-control me-md-2 mb-2 mb-md-0 w-100" placeholder="Enter your email" required />
                <HomeButton className={`${css.customButton} w-100 w-md-auto`} type="submit">Notify Me</HomeButton>
              </form>
              {message && <div className="mt-3 w-100">{message}</div>}
            </div>
          </div>
        </HomeCard>
        <HomeCard id='footer' >
          <a href="#top" className={css.logoHolder}>
            <div className={css.logoIcons}>
              <span>
                <LogoHorns fill='#000000' />
              </span>
              {/*<span>
                <LogoCattle fill='#000000' />
              </span>
              <span>
                <LogoPig fill='#000000' />
              </span>*/}
            </div>
            <h4>HerdSense</h4>
          </a>

          <HomeContent
            content={
              <>
                <p className={css.footerText}>HerdSense™<br></br>Redefining Herd Health Management</p>
              </>
            }
            classes='col-12'
          />

          <div className={css.linksContainer}>
            <Link to="/request-demo" onClick={() => window.scrollTo(0, 0)}>Request a demo</Link>
            <Link to="/support">Support</Link>
            <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy</Link>
          </div>
          <div className={css.footerHr} />
          <div>
            <p className={css.copyRight}>© 2024 HerdSense. All rights reserved.</p>
          </div>
        </HomeCard>


      </section>
    </>
  )
}

export default HomeProducers