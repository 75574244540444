import React from 'react'

export default function IconVideoRecorder(props) {
  const { fill = '#3E9CDB' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M14.6666 5.95392C14.6666 5.55004 14.6666 5.34811 14.5868 5.2546C14.5175 5.17346 14.4135 5.1304 14.3072 5.13877C14.1846 5.14842 14.0418 5.29121 13.7562 5.5768L11.3333 7.99967L13.7562 10.4226C14.0418 10.7081 14.1846 10.8509 14.3072 10.8606C14.4135 10.8689 14.5175 10.8259 14.5868 10.7448C14.6666 10.6512 14.6666 10.4493 14.6666 10.0454V5.95392Z" stroke={fill} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.33331 6.53301C1.33331 5.4129 1.33331 4.85285 1.5513 4.42503C1.74305 4.0487 2.04901 3.74274 2.42533 3.55099C2.85316 3.33301 3.41321 3.33301 4.53331 3.33301H8.13331C9.25342 3.33301 9.81347 3.33301 10.2413 3.55099C10.6176 3.74274 10.9236 4.0487 11.1153 4.42503C11.3333 4.85285 11.3333 5.4129 11.3333 6.53301V9.46634C11.3333 10.5864 11.3333 11.1465 11.1153 11.5743C10.9236 11.9506 10.6176 12.2566 10.2413 12.4484C9.81347 12.6663 9.25342 12.6663 8.13331 12.6663H4.53331C3.41321 12.6663 2.85316 12.6663 2.42533 12.4484C2.04901 12.2566 1.74305 11.9506 1.5513 11.5743C1.33331 11.1465 1.33331 10.5864 1.33331 9.46634V6.53301Z" stroke={fill} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
