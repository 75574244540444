import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';

// images
import imgAnalytics from '../images/analytics.jpg';
import imgEcosystems from '../images/ecosystems.jpg';
import imgGrow from '../images/grow.jpg';
import imgHero from '../images/hero.jpg';
import imgInTheKnow from '../images/in-the-know.jpg';
import imgAiDriven from '../images/ai-driven-cattle.jpg';
import imgScience from '../images/science-cattle.jpg';
import imgInstall from '../images/how-integrate.jpg'
import imgMonitoring from '../images/how-real-time.jpg'
import imgInstantAlerts from '../images/how-instant-alerts.jpg'
import imgData from '../images/how-data-driven.jpg'
import imgInstallM from '../images/how-integrate-m.png'
import imgMonitoringM from '../images/how-real-time-m.png'
import imgInstantAlertsM from '../images/how-instant-alerts-m.png'
import imgDataM from '../images/how-data-driven-m.png'
import LogoHorns from '../icons/LogoHorns';
import LogoCattle from '../icons/LogoCattle';
import LogoPig from '../icons/LogoPig';

import css from './styles.module.scss';
import Topbar from './Topbar';

import HomeHero from './Components/HomeHero/HomeHero';
import HomeCard from './Components/HomeCard/HomeCard';
import HomeButton from './Components/Button/HomeButton';
import LogoBlock from '../components/LogoBlock/LogoBlock';
import IconAI from '../icons/AI';
import IconCamera from '../icons/Camera';
import IconSecurity from '../icons/Security';
import IconPrivacy from '../icons/Privacy';
import IconBilling from '../icons/Billing';
import IconHardware from '../icons/Hardware';
import IconConnectivity from '../icons/Connectivity';
import IconHealth from '../icons/Health';
import IconAnalytics from '../icons/Analytics';
import IconCompliance from '../icons/Compliance';
import IconCart from '../icons/Cart';
import IconProfit from '../icons/Profit';
import IconAlerts from '../icons/Alerts';
import IconTracking from '../icons/Tracking';
import IconIdentification from '../icons/Identification';
import IconHeadHanging from '../icons/HeadHanging';
import IconTag from '../icons/Tag';
import IconLimping from '../icons/Limping';
import IconInstall from '../icons/Install';
import IconMonitoring from '../icons/Monitoring';
import IconInstantAlerts from '../icons/InstantAlerts';
import IconData from '../icons/Data';
import IconCommication from '../icons/Communication';
import IconNoTouch from '../icons/NoTouch';
import IconTaylored from '../icons/Taylored';
import IconProactive from '../icons/Proactive';
import IconRTMonitoring from '../icons/RTMonitoring';
import Home from './Home';

const HomeContent = ({ title = '', content = '', classes = 'col-12' }) => {
  return (
    <div className={`d-flex flex-column position-relative ${classes} ${css.homeContent} ${css.prod}`} style={{ zIndex: 10 }}>
      {title && <h2 className={`mb-0`}>{title}</h2>}
      {content}
    </div>
  );
};



const HowItWorks = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  
    const scriptUrl = "https://script.google.com/macros/s/AKfycbzE6y99VC95zwjvE131svEfCtpsMmk1ocLQ-_yEfYIQVrv1zCnhTXr6w1hNHM1z9bNk/exec"
  
    const handleSubmitForm = (e) =>{
      e.preventDefault()
  
      fetch(scriptUrl, {method: 'POST', body: new FormData(formRef.current)})
      .then(res => {
        alert("SUCCESSFULLY SUBMITTED")
      });
    }
  return (
    <>
      <Topbar />
      <main id="top" className={`${css.main} ${css.bgWhite}`}>
      <section className={`container d-flex flex-column ${css.container}`}>
        
        <HomeCard id='howitworks'>
        <a href='./' className={css.backButton}>&lt; &nbsp;  Back</a>
          <HomeContent
            title='Improved Outcomes for Every Member of Your Team'
            content={
              <>
              
                <p>HerdSense helps you focus on animals that need attention, improving your efficiency and response time.</p>
                <h3>Ranchers</h3>
                <p>Get detailed insights into the health and well-being of your entire herd, even when you’re not on-site.</p>
                <h3>Veterinarians</h3>
                <p>Collaborate seamlessly with ranch managers using detailed data, improving treatment and care decisions.</p>
                <h3>Pen Riders</h3>
                <p>HerdSense helps you focus on animals that need attention, improving your efficiency and response time. </p>
              </>
            }
            classes='col-12 col-md-8' />
        </HomeCard>

        <HomeCard id='features'>
            <div className={`col-lg-12 ${css.featuresContainer}`}>
              <div className={`${css.contentContainer}`}>
                <div className={`${css.textContainer}`}>
                  <h4>Features</h4>
                  <h3>HerdSense features</h3>
                  <p>HerdSense equips you with advanced tools to protect and care for your herd with confidence. With real-time health monitoring, proactive alerts, and tailored care, you can address issues before they escalate. Streamline operations with non-touch ID and real-time communication, ensuring seamless management and better outcomes for every animal.</p>
                </div>
                <div className={`d-flex flex-wrap ${css.iconBoxesContainer}`}>
                  <div className={`${css.iconBox} d-flex align-items-center`}>
                    <IconRTMonitoring title='' bgcolor='#ffffff' />
                    <div className={css.textContainer}>
                      <h4 className={`${css.featureTitle}`}>Real-time health monitoring</h4>
                      <p className={`${css.small}`}>Track critical health metrics such as activity levels, time spent at feed and water, and posture – for every individual animal in your herd.</p>
                    </div>
                  </div>
                  <div className={`${css.iconBox} d-flex align-items-center`}>
                    <IconProactive title='' bgcolor='#3099FD' />
                    <div className={css.textContainer}>
                    <h4 className={`${css.featureTitle}`}>Proactive alerts</h4>
                      <p className={`${css.small}`}>Receive immediate notifications for deviations in behavior, such as limping or changes in eating patterns, so you can intervene before a minor issue becomes a crisis.</p>
                    </div>
                  </div>
                  <div className={`${css.iconBox} d-flex align-items-center`}>
                    <IconTaylored title='' bgcolor='#3099FD' />
                    <div className={css.textContainer}>
                      <h4 className={`${css.featureTitle}`}>Tailored care</h4>
                      <p className={`${css.small}`}>Track health indicators for individual animals to ensure each animal gets the care it needs when needed.</p>
                    </div>
                  </div>
                </div>
                <div className={`d-flex flex-wrap ${css.iconBoxesContainer}`}>
                  <div className={`${css.iconBox} d-flex align-items-center`}>
                    <IconNoTouch title='' bgcolor='#ffffff' />
                    <div className={css.textContainer}>
                      <h4 className={`${css.featureTitle}`}>Non-touch ID</h4>
                      <p className={`${css.small}`}>Our touch-free identification system reduces stress during animal relocation, improving overall herd management.</p>
                    </div>
                  </div>
                  <div className={`${css.iconBox} d-flex align-items-center`}>
                    <IconCommication title='' bgcolor='#3099FD' />
                    <div className={css.textContainer}>
                      <h4 className={`${css.featureTitle}`}>Real-time communication</h4>
                      <p className={`${css.small}`}>Seamlessly communicate with your team and veterinarians through mobile devices, improving response times and collaboration.</p>
                    </div>
                  </div>
                  <div className={`${css.iconBox} d-flex align-items-center`}>
                    
                  </div>
                </div>
              </div>
            </div>
          </HomeCard>
        <HomeCard id='intallIntegrate'>
            <h2 className={`mb-5 mt-5 text-center`}>How HerdSense works</h2>
            <div className="align-items-center d-flex flex-row flex-wrap" style={{ width: '100%' }}>
              <div className={`${css.iconboxLeft} col-lg-6 col-md-12 d-flex flex-column justify-content-center align-items-center'`} style={{  height: '100%' }}>
                <div className={`${css.iconBox}  d-flex flex-column align-items-start`}>
                  <IconInstall title='' bgcolor='#ffffff' />
                  <div className={css.textContainer}>
                    <h3>Install and integrate</h3>
                    <p>HerdSense seamlessly adapts to your existing infrastructure for hassle-free setup.</p>
                  </div>
                </div>
              </div>
              <div className={`col-lg-6 col-md-12 ${css.featureBgImg} ${css.integrate}`} style={{ backgroundImage: `url(${imgInstall})`, backgroundSize: 'contain', backgroundPosition: 'center left', backgroundRepeat: 'no-repeat', backgroundColor: '#F2F4F7', height: '512px' }}>
              {/* Additional content can go here if needed */}
              </div>
            </div>
          </HomeCard>
        <HomeCard id='realtimeMonitoring'>
          <div className="align-items-center d-flex flex-row flex-wrap" style={{ width: '100%' }}>
          <div className={`col-lg-6 col-md-12 order-2 order-lg-1 ${css.featureBgImg} ${css.monitoring}`} style={{ flex: '1 1 50%', backgroundImage: `url(${imgMonitoring})`, backgroundSize: 'contain', backgroundPosition: 'center right', backgroundRepeat:'no-repeat', backgroundColor:'#F2F4F7', height: '512px' }}>
              {/* Additional content can go here if needed */}
            </div>
            <div className={`${css.iconboxRight} col-lg-6 col-md-12 order-1 order-lg-2 d-flex flex-column justify-content-center align-items-center'`} style={{  height: '100%' }}>
            <div className={`${css.iconBox} d-flex flex-column align-items-left`} >
                <IconMonitoring title='' bgcolor='#ffffff' />
                <div className={css.textContainer}>
                  <h3>Monitor in real-time</h3>
                  <p>Track health indicators like activity, feeding patterns, and movement. </p>
                </div>
              </div>
            </div>
          </div>
        </HomeCard>
        <HomeCard id='instantAlerts'>
          <div className="align-items-center d-flex flex-row flex-wrap" style={{ width: '100%' }}>
          <div className={`${css.iconboxLeft} col-lg-6 d-flex flex-column justify-content-center align-items-center'`} style={{ flex: '1 1 50%', height: '100%' }}>
              <div className={`${css.iconBox} d-flex flex-column align-items-left`}>
                <IconInstantAlerts title='' bgcolor='#ffffff' />
                <div className={css.textContainer}>
                  <h3>Receive instant alerts</h3>
                  <p>Notifications highlight subtle changes in behavior or condition.</p>
                </div>
              </div>
            </div>
            <div className={`col-lg-6 col-md-12 ${css.featureBgImg} ${css.alerts}`} style={{ flex: '1 1 50%', backgroundImage: `url(${imgInstantAlerts})`, backgroundSize: 'contain', backgroundPosition: 'center left', backgroundRepeat:'no-repeat', backgroundColor:'#F2F4F7', height: '512px' }}>
              {/* Additional content can go here if needed */}
            </div>
          </div>
        </HomeCard>
        <HomeCard id='dataDriven'>
          <div className="align-items-center d-flex flex-row flex-wrap" style={{ width: '100%' }}>
            <div className={`col-lg-6 order-2 order-lg-1 ${css.featureBgImg} ${css.data}`} style={{ flex: '1 1 50%', backgroundImage: `url(${imgData})`, backgroundSize: 'contain', backgroundPosition: 'center right', backgroundRepeat:'no-repeat', backgroundColor:'#F2F4F7', height: '512px' }}>
              {/* Additional content can go here if needed */}
            </div>
            <div className={`${css.iconboxRight} col-lg-6 col-md-12 order-1 order-lg-2 d-flex flex-column justify-content-center align-items-center'`} style={{  height: '100%' }}>
              <div className={`${css.iconBox} d-flex flex-column align-items-left`}>
                <IconData title='' bgcolor='#ffffff' />
                <div className={css.textContainer}>
                  <h3>Data-driven decisions</h3>
                  <p>Work with your veterinarian to create precise treatment plans using detailed, AI-driven data.</p>
                </div>
              </div>
            </div>
          </div>
        </HomeCard>

        <HomeCard id='requestdemo'>
          <div className="row"style={{height:'1px', width:'100%', backgroundColor:'#E4E7EC', margin:'15px 0 50px 0'}}></div>
          <HomeContent
            title='Ready to protect your herd?'
            content={
              <>
                <p>Get started today and see the difference HerdSense can make. Whether you manage a small operation or a large feedlot, HerdSense gives you the tools to monitor and maintain the health of your herd, reducing costs and improving productivity.</p>
                <h4>Contact us for a demo</h4>
                <p>Find out how HerdSense can help you improve herd health and maximize profitability.</p>
                <HomeButton className={css.cbutton} onClick={() => alert('Regular Button Clicked!')}>Request a demo</HomeButton>
              </>
            }
            classes='col-12 col-md-8' />
        </HomeCard>
        <HomeCard id='getNotified'>
          <div className="row">
            <div className="col-12 col-md-8">
              <HomeContent
                title='Get notified when we launch'
                content={
                  <>
                    <p>HerdSense is currently in a pilot trial phase and is not yet available for commercial purchase. Our innovative approach to herd health management is being evaluated to ensure it meets the high standards you expect.</p>
                    <p>Want to be the first to know when HerdSense becomes available? Enter your email, and we’ll keep you updated.</p>
                    <p>For any immediate questions, feel free to reach out to us at info@herdsense.com.</p>
                  </>
                }
                classes='col-12'
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-start">
            <form className="w-100 d-flex flex-column flex-md-row">
  <input type="email" className="form-control me-md-2 mb-2 mb-md-0 w-100" placeholder="Enter your email" />
  <HomeButton className={`${css.customButton} w-100 w-md-auto`} onClick={() => alert('Button Clicked!')}>Notify Me</HomeButton>
</form>
            </div>
          </div>
        </HomeCard>
        <HomeCard id='footer' >
          <a href="#top" className={css.logoHolder}>
            <div className={css.logoIcons}>
              <span>
                <LogoHorns fill='#000000' />
              </span>
              {/*<span>
                <LogoCattle fill='#000000' />
              </span>
              <span>
                <LogoPig fill='#000000' />
              </span>*/}
            </div>
            <h4>HerdSense</h4>
          </a>

          <HomeContent
            content={
              <>
                <p className={css.footerText}>HerdSense™<br></br>Redefining Herd Health Management</p>
              </>
            }
            classes='col-12'
          />

          <div className={css.linksContainer}>
            <a href="#link1">Request a demo</a>
            <a href="#link2">Support</a>
            <a href="#link3">Privacy</a>
          </div>
          <div className={css.footerHr} />
          <div>
            <p className={css.copyRight}>© 2024 HerdSense. All rights reserved.</p>
          </div>
        </HomeCard>
      </section>
    </main>
    </>
  );
};

export default HowItWorks;