import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageHeader from './Header';
import NoteTable from './NoteTable';
import MapView from './MapView';

import css from './styles.module.scss';

import useQuery from '../../hooks/useQuery';

import Loader from '../../components/Loader';
import Client from '../../apis/Client';

function Dashboard() {
  const [params] = useSearchParams();

  const locationId = params.get('locationId');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['get_location_poles', locationId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/devices`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const filteredData = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    return data.filter(p => p.latitude !== null && p.longitude !== null);
  }, [data]);

  let noDataMsg = null;
  if (!filteredData?.length) {
    noDataMsg = <span className={css.noPoles}>Sorry, there are no poles found at current location.</span>;
  }

  return (
    <>
      <PageHeader locationId={locationId} />

      <div className={css.content}>
        {noDataMsg}

        <Loader loading={isLoading}>
          <MapView
            locationId={locationId}
            data={filteredData}
            isFetching={isFetching}
          />

          <NoteTable
            locationId={locationId}
          />
        </Loader>
      </div>
    </>
  );
}

export default Dashboard;
