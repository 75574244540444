import React from 'react';
import { Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import CattleGraphInfo from './CattleGraphInfo';

import { getAbsDay } from '../../../util';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(annotationPlugin);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        display: false
      },
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        stepSize: 1
      }
    },
  }
};

const commonGraphProps = {
  barPercetage: 0.5,
  barThickness: 12,
  maxBarThickness: 12,
  minBarLength: 2
};

const commonLineAnnoProps = {
  type: 'line',
  label: {
    backgroundColor: 'transparent',
    color: '#383838',
    position: 'start',
    display: false,
    xAdjust: -20
  },
  xMin: -0.5
};

function CattleGraph(props) {
  const {
    data,
    startDate = new Date(),
    endDate = new Date(),
    header,
    avg,
    graphLabel,
    timeLabel,
    minDaysShown = 14,
    valueKey,
    multiple = false,
    barColor = 'rgb(48 153 253 / 50%)',
    graphHeight = 120
  } = props;

  const start = getAbsDay(startDate);
  const end = getAbsDay(endDate);

  const numberOfDays = Math.max(end - start, minDaysShown);

  const labels = new Array(numberOfDays).fill('').map((str, i) => {
    const time = (end - i) * 1000 * 60 * 60 * 24;
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const timestamp = new Date(time + offset);
    return `${timestamp.getMonth() + 1}/${timestamp.getDate()}`;
  }).reverse();

  const graphData = {
    labels,
    datasets: []
  }

  if (multiple) {
    graphData.datasets = data?.map((set, setIndex) => {
      const perDayData = set.reduce((arr, it) => {
        const itDay = getAbsDay(it.created_at);
        const index = end - itDay + 1;
        arr[index] = parseInt(it[valueKey]);
        return arr;
      }, new Array(numberOfDays).fill(0)).reverse();

      return {
        label: Array.isArray(graphLabel) ? graphLabel[setIndex] || graphLabel : graphLabel,
        backgroundColor: Array.isArray(barColor) ? barColor[setIndex] || barColor : barColor,
        ...commonGraphProps,
        data: perDayData
      }
    });
  } else {
    const perDayData = data?.reduce((arr, it) => {
      const itDay = getAbsDay(it.created_at);
      const index = end - itDay + 1;
      arr[index] = it[valueKey];
      return arr;
    }, new Array(numberOfDays).fill(0)).reverse();

    graphData.datasets = [
      {
        label: graphLabel,
        backgroundColor: barColor,
        ...commonGraphProps,
        data: perDayData
      },
    ]
  }

  // clone options
  const graphOptions = {
    ...options,
    plugins: { ...options.plugins }
  };

  if (avg) {
    graphOptions.plugins.annotation = {
      clip: false,
      annotations: {
        tenPercAbove: {
          ...commonLineAnnoProps,
          label: {
            ...commonLineAnnoProps.label,
            content: '+10%',
            yAdjust: -14
          },
          borderColor: '#383838aa',
          borderDash: [4, 4, 8, 4],
          yMin: avg * 1.1,
          yMax: avg * 1.1,
        },
        avg: {
          ...commonLineAnnoProps,
          label: {
            ...commonLineAnnoProps.label,
            content: 'Avg',
            yAdjust: -4
          },
          borderColor: '#383838',
          yMin: avg,
          yMax: avg,
        },
        tenPercBelow: {
          ...commonLineAnnoProps,
          label: {
            ...commonLineAnnoProps.label,
            content: '-10%',
            yAdjust: 2
          },
          borderColor: '#383838aa',
          borderDash: [4, 4, 8, 4],
          yMin: avg * 0.9,
          yMax: avg * 0.9,
        },
      }
    };
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', color: '#383838', alignItems: 'center', justifyContent: 'space-between' }}>
        <h3>{header}</h3>
        <span style={{ fontSize: 12 }}>{timeLabel}</span>
      </div>
      <Bar data={graphData} options={graphOptions} height={graphHeight} />
      <CattleGraphInfo visible={!!avg} />
    </div>
  );
}

export default CattleGraph;
