import React, { useState, useRef } from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';

import Client from '../../apis/Client';

import useMutation from '../../hooks/useMutation';

import Loader from '../../components/Loader/Loader';
import PageSubTitle from '../../components/PageSubTitle/PageSubTitle';
import CustomTable from '../../components/CustomTable';
import AlertModal from '../../components/AlertModal';

import useQuery from '../../hooks/useQuery';

import UserModal from './UserModal';

import css from '../styles.module.scss';

export default function GlobalUsers() {
    const { customerId } = useParams();

    const navigate = useNavigate();

    const [showUserModal, setShowUserModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInviteSentModal, setShowInviteSentModal] = useState(false);

    const activeUser = useRef(null);

    const { isLoading, isError, error, data, refetch } = useQuery({
        queryFn: () => Client.get(`/api/v1/admin/customers/${customerId}/users`),
        queryKey: ['customer', customerId],
        useErrorBoundary: (error) => error.status === 401,
    });

    const { mutate: resendInviteRequest, isLoading: resendInviteLoading } =
    useMutation({
        mutationKey: ['resend-user-invite'],
        mutationFn: (user) => {
            return Client.post(`/api/v1/auth/invite/resend`, { accountId: user.id });
        },
        onSuccess: () => {
            setShowInviteSentModal(true);
        },
    });

    const { mutate: deleteAccount, isLoading: deleteAccountLoading } =
    useMutation({
        mutationKey: ['remove-user'],
        mutationFn: () => {
            return Client.delete(`/api/v1/admin/customers/${customerId}/users/${activeUser.current.id}`);
        },
        onSuccess: () => {
            setShowDeleteModal(false);
            refetch();
        },
    });

    if (isError) {
        return <h4 style={{ color: 'red' }}>{error.msg}</h4>;
    }

    if (isLoading) {
        return <Loader></Loader>;
    }

    const columnsInfo = [
        {
            name: 'name',
            label: 'User Name',
            valFn: (datum) => {
                return `${datum.user.first_name} ${datum.user.last_name}`;
            },
        },
        {
            name: 'phone',
            label: 'Phone',
            valFn: (datum) => {
                return datum.user.phone;
            },
        },
        {
            name: 'email',
            label: 'Email',
            valFn: (datum) => {
                return datum.user.email;
            },
        },
        {
            name: 'uiRolesStr',
            label: 'Roles',
        },
        {
            name: 'created_at',
            label: 'Date Joined',
            valFn: (datum) => {
                return datum.is_invite ? 'Pending' : datum.created_at;
            },
        },
        {
            name: 'status',
            label: 'Status',
        },
        {
            name: 'action-1',
            label: '',
            componentFn: (user) => user.status === 'pending'
                ? (
                    <button
                        // disabled={resendInviteLoading}
                        type="button"
                        className='btn btn-outline-primary mx-3'
                        onClick={(e) => {
                            e.stopPropagation();
                            activeUser.current = { ...user };
                            resendInviteRequest(user);
                        }}
                    >
                        Resend
                    </button>
                )
                : null
        },
        {
            name: 'action-2',
            label: '',
            componentFn: (user) => (
                <button
                    type="button"
                    className='btn btn-danger'
                    onClick={(e) => {
                        e.stopPropagation();
                        activeUser.current = { ...user };
                        setShowDeleteModal(true);
                    }}
                >
                    Remove
                </button>
            )
        }
    ];

    return (
        <>
            <div id={css.glActions}>
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/admin/customers')}>
                    Back
                </button>
            </div>

            <div id={css.overview} className="tableContain">
                <div id={css.titleLocation}>
                    <PageSubTitle title="Company Users" />
                </div>
            </div>

            <div className={`bgWhite tableContain`}>
                <div style={{ width: '100%', height: 'calc(100vh - 380px)' }}>
                    <CustomTable
                        rows={data}
                        columns={columnsInfo}
                        onRowClick={(e, user) => {
                            activeUser.current = { ...user };
                            setShowUserModal(true);
                        }}
                    />
                </div>
                <div id={css.actionsBlock} className='actions'>
                    <button
                        type="button"
                        className='btn btn-primary'
                        onClick={() => {
                            activeUser.current = null;
                            setShowUserModal(true);
                        }}>
                        Add New User
                    </button>
                </div>
            </div>

            <UserModal
                setShowModal={setShowUserModal}
                showModal={showUserModal}
                user={activeUser.current}
                companyId={customerId}
                refetch={refetch}
            />

            <AlertModal
                onAccept={deleteAccount}
                onCancel={() => setShowDeleteModal(false)}
                show={showDeleteModal}
                title="Remove User"
                btn1="No"
                btn2="Yes"
                message="Would you like to remove user?"
            />

            <AlertModal
                onAccept={() => setShowInviteSentModal(false)}
                onCancel={() => setShowInviteSentModal(false)}
                show={showInviteSentModal}
                title="Invite sent"
                btn1={null}
                btn2="Close"
                message={`Email invitation have been sent to ${activeUser?.current?.user?.email}`}
            />
        </>
    );
}
