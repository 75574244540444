import React, { useContext, useState, useRef, useEffect } from 'react';

import TopHeader from '../../components/TopHeader';

import Client from '../../apis/Client';

import LocationContext from '../../context/LocationContext';
import CameraStackContext from '../../context/CameraStackContext';
import AuthContext from '../../context/AuthContext';

import CompanySelect from '../../components/CompanySelect';
import LocationSelect from '../../components/LocationSelect';

import css from './styles.module.scss';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';

function Topbar(props) {
  const {
    navOpen = false,
    setNavOpen = () => {},
    blank = false,
  } = props;

  const { locationId } = useContext(LocationContext);
  const { logout, userData } = useContext(AuthContext);
  const { stack: cameraStack, closeTab } = useContext(CameraStackContext);

  const dropdownRef = useRef();

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const blurListener = (e) => {
      if (!dropdownRef.current?.contains(e.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('click', blurListener);

    return () => {
      document.removeEventListener('click', blurListener);
    }
  }, []);

  const isProducerAdmin =
    userData &&
    (userData.roles || []).find((role) => {
      return (
        (role.type === 'producer' && role.name === 'admin') ||
        (role.type === 'system' && role.name === 'admin')
      );
    });

  const queryString = locationId ? `?locationId=${locationId}` : '';

  const links = [
    {
      title: 'Dashboard',
      path: `/producers`,
    },
    // {
    //   title: 'HerdSense',
    //   path: `/producers/herd-sense`,
    // },
    // {
    //   title: 'Vet Chat',
    //   path: `/producers/vet-chat`,
    // },
  ];

  const scrollableLinks = [
    ...cameraStack.map(device => ({
      title: device.name,
      path: `/producers/herd-sense`,
      queryParams: { deviceId: device.id },
      cancelable: true
    }))
  ];

  // if (isProducerAdmin) {
  //   links.push({
  //     title: 'Users',
  //     path: `/producers/users`
  //   });
  // }

  let accountEl = null;
  let usersEl = null;
  if (isProducerAdmin) {
    accountEl = (
      <Link
        to={'/producers/account'}
        className={css.link}
        onClick={() => setShowDropdown(false)}
      >
        My Account
      </Link>
    );

    usersEl = (
      <Link
        to={'/producers/users'}
        className={css.link}
        onClick={() => setShowDropdown(false)}
      >
        Users
      </Link>
    );
  }

  const companySelectEl = (
    <div className={css.locSelect}>
      <h5>Company:</h5>
      <CompanySelect />
    </div>
  );

  const settingsDropdown = (
    <div
      ref={dropdownRef}
      className={css.dropdownContainer}
      onClick={() => setShowDropdown(!showDropdown)}
      id="settings-menu-item"
    >
      <Icon icon="Settings" />
      
      <div
        className={`${css.dropdown} ${showDropdown ? css.open : ''}`}
        onClick={e => e.stopPropagation()}
      >
        {userData?.first_name &&
          <h5>
          Hi, <span>{userData?.first_name}!</span>
          <span className={css.email}>{userData?.email}</span>
          </h5>
        }

        {accountEl}

        {usersEl}

        {companySelectEl}

        <div className={css.locSelect}>
          <h5>Location:</h5>
          <LocationSelect hideLabel url="/api/v1/producers/locations" />
        </div>
        
        <button onClick={logout} className='btn btn-danger'>Logout</button>
      </div>
    </div>
  );

  const company = userData?.company?.display_name || userData?.company?.name;

  return (
    <TopHeader
      links={links}
      scrollableLinks={scrollableLinks}
      scrollableWidth="400px"
      onCancel={link => closeTab(link.queryParams.deviceId)}
      email={(userData || {}).email}
      query={queryString}
      customEls={settingsDropdown}
      navOpen={navOpen}
      setNavOpen={setNavOpen}
      label={`HerdSense - ${company}`}
      blank={blank}
      homeLink="/producers"
    />
  );
}

export default Topbar;
