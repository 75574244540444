import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import css from '../styles.module.scss';
import Accordion from 'react-bootstrap/Accordion';
import IconPen from '../../../icons/Pen';
import IconCarat from '../../../icons/Carat';
import IconNoFeed from '../../../icons/IconNoFeed';
import IconNoVideo from '../../../icons/icon-no-video';
import IconVideoRecorder from '../../../icons/icon-video-recorder';

function PenListView(props) {
  const { locationId, data } = props;

  const [search, setSearch] = useState('');

  const searchRegex = new RegExp(`^${search}`);

  const filteredData = data
    .sort((a, b) => a.name < b.name ? -1 : 1)
    .filter((d) => {
      return searchRegex.test(d.name)
        || searchRegex.test(d?.monitoring_area?.name)
    });

  const monitoringAreas = Object.values(
    filteredData.reduce((map, device) => {
      const areaId = device?.monitoring_area?.id;

      if (!areaId) {
        return map;
      }

      if (!map[areaId]) {
        map[areaId] = {
          ...device.monitoring_area,
          devices: [device]
        };
      } else {
        map[areaId].devices.push(device);
      }

      return map;
    }, {})
  ).sort((a, b) => a.name < b.name ? -1 : 1);

  const FIFTEEN_MINUTES = 1000 * 60 * 15;

  const areaEls = monitoringAreas.map((area, index) => {
    const deviceEls = area.devices.map(d => {
      const online = new Date().getTime() - new Date(d?.last_heartbeat).getTime() < FIFTEEN_MINUTES;

      const noFeedIconEl = online ? <IconVideoRecorder /> : <IconNoVideo />;

      return (
        <div key={d.id} className={css.camera}>
          <Link to={`/producers/herd-sense?locationId=${locationId}&deviceId=${d.id}`}>
            <div className={`${css.icon} ${online && css.active}`}>{noFeedIconEl}</div>
            <span>{d.name}</span>
          </Link>
        </div>
      )
    });

    const defaultId = area.devices[0].id;

    return (
      <Accordion.Item key={area.id} eventKey={index} className={css.item}>
        <Accordion.Header className={css.header}>
          <Link to={`/producers/herd-sense?locationId=${locationId}&deviceId=${defaultId}`}>
            <IconPen />
            {area?.name}
          </Link>
          <IconCarat />
        </Accordion.Header>
        <Accordion.Body className={css.monitoringDeviceList}>
          {deviceEls}
        </Accordion.Body>
      </Accordion.Item>
    );
  });

  return (
    <div className={css.listContainer}>
      <div className={css.searchBlock}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.3359 9.77506L13.7871 13.4229C14.0791 13.7316 14.0694 14.2236 13.766 14.5203C13.6232 14.66 13.4351 14.7368 13.2368 14.7368C13.0273 14.7368 12.8318 14.6522 12.6864 14.4988L9.20899 10.8233C8.22158 11.5285 7.06667 11.9 5.85474 11.9C2.62649 11.9 0 9.23077 0 5.94999C0 2.66922 2.62649 0 5.85474 0C9.08299 0 11.7095 2.66922 11.7095 5.94999C11.7095 7.35419 11.2233 8.70303 10.3359 9.77506ZM10.3158 5.89474C10.3158 3.45692 8.33256 1.47368 5.89474 1.47368C3.45692 1.47368 1.47368 3.45692 1.47368 5.89474C1.47368 8.33256 3.45692 10.3158 5.89474 10.3158C8.33256 10.3158 10.3158 8.33256 10.3158 5.89474Z" fill="#29313B"/>
        </svg>
        <input
          value={search}
          onChange={e => setSearch(e.target.value)} />
      </div>
      <Accordion className={css.accordion} defaultActiveKey={0} alwaysOpen>
        {areaEls}
      </Accordion>
    </div>
  );
}

export default PenListView;
