import React, {useState} from 'react'

import css from './styles.module.scss'

import HomeDevelopers from './HomeDevelopers'
import HomeProducers from './HomeProducers'

import Topbar from './Topbar';

const Home = () => {
  const [showContent, setShowContent] = useState('producers')

  const content = showContent === 'producers'
    ? <HomeProducers />
    : <HomeDevelopers />;

  return (
    <main id="top" className={`${css.main} ${css.bgWhite}`}>
      <Topbar />
      {content}
    </main>
  )
}

export default Home