import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from './context/AuthContext';

function NotFound() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

	return (
		<div>
			<h1>404 Not Found</h1>

      <button onClick={handleLogout}>logout</button>
		</div>
	);
}

export default NotFound;
