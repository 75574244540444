import React from 'react'

export default function IconNoVideo(props) {
  const { fill = '#3E9CDB' } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
      <path d="M1 1L12 11M11.3333 5.99964L13.7562 3.57678C14.0418 3.2912 14.1846 3.14841 14.3072 3.13876C14.4136 3.13039 14.5175 3.17345 14.5868 3.25458C14.6667 3.34809 14.6667 3.55003 14.6667 3.9539V8.04538C14.6667 8.44925 14.6667 8.65119 14.5868 8.7447C14.5175 8.82583 14.4136 8.86889 14.3072 8.86052C14.1846 8.85087 14.0418 8.70808 13.7562 8.4225L11.3333 5.99964ZM4.53334 10.6663H8.13334C9.25345 10.6663 9.8135 10.6663 10.2413 10.4483C10.6176 10.2565 10.9236 9.95058 11.1154 9.57426C11.3333 9.14644 11.3333 8.58639 11.3333 7.46629V4.53298C11.3333 3.41289 11.3333 2.85284 11.1154 2.42502C10.9236 2.0487 10.6176 1.74274 10.2413 1.55099C9.8135 1.33301 9.25345 1.33301 8.13334 1.33301H4.53334C3.41324 1.33301 2.85319 1.33301 2.42536 1.55099C2.04904 1.74274 1.74308 2.0487 1.55133 2.42502C1.33334 2.85284 1.33334 3.41289 1.33334 4.53298V7.46629C1.33334 8.58639 1.33334 9.14644 1.55133 9.57426C1.74308 9.95058 2.04904 10.2565 2.42536 10.4483C2.85319 10.6663 3.41324 10.6663 4.53334 10.6663Z" stroke={fill} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
