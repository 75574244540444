import Hls from 'hls.js';

import Renderer from './Renderer';
import EventHandler from './EventHandler';

const FRAMES_PER_SECOND = 30;

const RESET_INTERVAL = 3000;

class Streamer {
  constructor() {
    this.resetTimeout = null; // use to check if video is stale
    this.lastFrame = null;
    this.hls = null;
    this.jsMpeg = null;

    this.frameTimestamp = null;
    this.framePrevTimestamp = null;

    this.container = null;
    this.deviceIP = null;
    this.videoSrc = null;
    this.canvas = null;

    this.accumulator = 0;
    this.destroyed = false;

    this.renderer = null;
    this.eventHandler = null;

    this.onClick = () => {};
    this.onFatalError = () => {};
    this.onData = () => {};
    this.onFragLoaded = () => {};

    this.shouldDrawBoxes = false;
    this.shouldDrawPoses = false;
    this.shouldDrawGIds = false;
    this.shouldDrawTimestamp = false;

    this.canDrawIcons = false;
    this.shouldDrawMovement = true;
    this.shouldDrawHeadPos = true;

    this.hoverId = null;
    this.activeId = null;

    this.trackActiveId = false;

    window.requestAnimationFrame(this.onFrameAnimation.bind(this));

    this.listeners = [];

    this.confidence = 1;
  }

  set showIds(bool) {
    this.shouldDrawGIds = bool;
  }

  set isDebugView(bool) {
    this.shouldDrawBoxes = bool;
    this.shouldDrawPoses = bool;
    this.shouldUseCurrentWeight = bool;
    this.canDrawIcons = bool;
    this.shouldDrawTimestamp = bool;
  }

  set active(id) {
    this.activeId = id;
    this.trackActiveId = !!id;
    if (!id) {
      this.center();
    }
  }

  zoomIn() {
    if (this.eventHandler) {
      this.eventHandler.zoomIn();
    }
  }

  zoomOut() {
    if (this.eventHandler) {
      this.eventHandler.zoomOut();
    }
  }

  center() {
    if (this.eventHandler) {
      this.eventHandler.center();
    }
  }

  toggleFullscreen() {
    if (this.eventHandler) {
      this.eventHandler.toggleFullscreen();
    }
  }

  frameCoordToCanvas(frame, zoom, offsetX, offsetY) {
    if (!frame?.payload) {
      return;
    }

    const { clientWidth, clientHeight } = this.videoSrc;
    const { frameWidth, frameHeight } = frame.payload;

    return (x, y) => {
      // convert to percent of screen
      const frameX = x / frameWidth;
      const frameY = y / frameHeight;

      // change center of screen to 0, 0
      const relX = (frameX - 0.5) * clientWidth * zoom;
      const relY = (frameY - 0.5) * clientHeight * zoom;

      // scale to screen size and add offsets
      const cameraX = relX + (clientWidth / 2) + offsetX;
      const cameraY = relY + (clientHeight / 2) + offsetY;

      return { x: cameraX, y: cameraY };
    }
  }

  snapshot(label = 'snapshot', overlay = false) {
    if (this.destroyed) {
      return;
    }

    const { clientWidth, clientHeight } = this.videoSrc;

    const snapshotCanvas = document.createElement('canvas');
    snapshotCanvas.width = clientWidth;
    snapshotCanvas.height = clientHeight;
    const ctx = snapshotCanvas.getContext('2d');

    ctx.drawImage(this.videoSrc, 0, 0, clientWidth, clientHeight);

    if (overlay) {
      const frameCoordToCanvasFn = this.frameCoordToCanvas(
        this.lastFrame,
        this.eventHandler.zoom,
        this.eventHandler.offsetX,
        this.eventHandler.offsetY
      );

      const payload = this.lastFrame?.payload;

      this.renderer.render(
        snapshotCanvas,
        payload,
        this.frameTimestamp,
        this.framePrevTimestamp,
        frameCoordToCanvasFn
      );
    }

    const link = document.createElement('a');
    link.download = overlay
      ? `${label}_overlay_${new Date().toISOString()}.png`
      : `${label}_${new Date().toISOString()}.png`;
    link.href = snapshotCanvas.toDataURL();
    link.click();
  }

  parseFrameData(lastFrame, data) {
    if (!data?.payload?.instances) {
      return data;
    }

    return { ...data };
  }

  async startHls() {
    if (this.destroyed) return false;

    const url = `https://${this.deviceIP}/primary/index.m3u8`;

    if (Hls.isSupported()) {
      await new Promise((resolve, reject) => {
        this.hls = new Hls({
          enableWorker: true,
          maxBufferLength: 1,
          liveBackBufferLength: 0,
          liveSyncDuration: 1,
          liveMaxLatencyDuration: 5,
          liveDurationInfinity: true,
          highBufferWatchdogPeriod: 1,
        });

        this.hls.on(Hls.Events.ERROR, (evt, data) => {
          if (!data.fatal) return;

          this.hls.destroy();

          this.onFatalError();
          reject('could not start stream');
        });

        this.hls.on(Hls.Events.MANIFEST_LOADED, () => {
          resolve();
        });

        this.hls.on(Hls.Events.FRAG_LOADED, () => {
          this.onFragLoaded();
          clearTimeout(this.resetTimeout);
          this.resetTimeout = setTimeout(() => {
            this.onFatalError();
          }, RESET_INTERVAL);
        });

        this.hls.loadSource(url);
        this.hls.attachMedia(this.videoSrc);
      });
    } else if (this.videoSrc.canPlayType('application/vnd.apple.mpegurl')) {
      // since it's not possible to detect timeout errors in iOS,
      // wait for the playlist to be available before starting the stream
      await fetch(url);

      this.videoSrc.src = url;
    }

    return true;
  }

  async startJSMpeg(deviceId) {
    const wsUrl = window.location.hostname === 'localhost'
      ? 'ws://localhost:3001'
      : `wss://${window.location.host}/cameras`;

    const url = `${wsUrl}/cameras/${deviceId}`;

    this.jsMpeg = new window.JSMpeg.Player(url, {
      canvas: this.videoSrc,
      autoplay: true,
      onVideoDecode: () => {
        this.onFragLoaded();
      }
    });

    return true;
  }

  startSockets(socketClient, deviceId) {
    socketClient.subscribe(`device-frame-meta`, deviceId, (data) => {
      this.onData(data);

      const parsedFrameData = this.parseFrameData(this.lastFrame, data);
      this.lastFrame = parsedFrameData;
      this.framePrevTimestamp = this.frameTimestamp;
      this.frameTimestamp = new Date().getTime();
    });
  }

  stopSockets(socketClient, deviceId) {
    socketClient.unsubscribe('device-frame-meta', deviceId);
  }

  onFrameAnimation(timestamp) {
    if (this.destroyed) {
      return;
    }

    const delta = timestamp - this.prevTimestamp;
    this.prevTimestamp = timestamp;

    this.accumulator += delta;

    if (!this.renderer || this.accumulator < 1000 / FRAMES_PER_SECOND) {
      window.requestAnimationFrame(this.onFrameAnimation.bind(this));
      return;
    }

    this.accumulator = 0;

    this.renderer.clear(this.canvas);

    const frameCoordToCanvasFn = this.frameCoordToCanvas(
      this.lastFrame,
      this.eventHandler.zoom,
      this.eventHandler.offsetX,
      this.eventHandler.offsetY
    );

    const payload = this.lastFrame?.payload;

    this.eventHandler.registerClickBoxes(payload, frameCoordToCanvasFn);

    this.renderer.render(
      this.canvas,
      payload,
      this.frameTimestamp,
      this.framePrevTimestamp,
      frameCoordToCanvasFn
    );

    this.eventHandler.adjustScreen(payload);

    window.requestAnimationFrame(this.onFrameAnimation.bind(this));
  }

  async start(deviceIP, deviceId, isJSmpeg, container, videoSrc, canvas) {
    this.container = container;
    this.deviceIP = deviceIP;
    this.videoSrc = videoSrc;
    this.canvas = canvas;

    let started = false;
    try {
      if (isJSmpeg) {
        this.startJSMpeg(deviceId);
        started = true;
      } else {
        started = await this.startHls();
        await this.videoSrc.play();
      }
    } catch (err) {
      return false;
    }

    if (!started) {
      return false;
    }

    this.renderer = new Renderer(this);
    this.eventHandler = new EventHandler(this);
    this.eventHandler.start();

    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.videoSrc.addEventListener(event, fn);
    });

    return true;
  }

  stop() {
    this.destroyed = true;

    if (this.renderer) {
      this.renderer.clear(this.canvas);
      this.lastFrame = null;
      this.renderer = null;
    }

    if (this.eventHandler) {
      this.eventHandler.stop();
      this.eventHandler = null;
    }

    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }

    if (this.jsMpeg && this.jsMpeg.isPlaying) {
      this.jsMpeg.destroy();
      this.jsMpeg = null;
    }

    if (this.videoSrc) {
      this.listeners.forEach((listener) => {
        const { event, fn } = listener;
        this.videoSrc.removeEventListener(event, fn);
      });
    }
  }
}

export default Streamer;
